import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import "./LoginRegister.css";

import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
const notyf = new Notyf();

const Page = () => {
  const navigate = useNavigate();
  let params = useParams();
  ////console.log(params);

  const [post, setPost] = React.useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/pages?token=85708875aa7f8dc7d3d2752bfbce31",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            filter: { title_slug: "login-register" },
          }),
        }
      );
      await response
        .json()

        .then(function (response) {
          ////console.log("Page Data", response.entries[0]);
          setPost(response.entries[0]);
          setIsLoading(false);
        });
    };
    fetchData();
  }, [params.slug]);

  // SEND DATA
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  // Register Data
  const [nameReg, setNameReg] = useState("");
  const [nameReg2, setNameReg2] = useState("");
  const [userReg, setUserReg] = useState("");
  const [passwordReg, setPasswordReg] = useState("");

  const [Address1, setAddress1] = useState("");
  const [Address2, setAddress2] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [PostCode, setPostCode] = useState("");
  const [DayPhoneCC, setDayPhoneCC] = useState("968");
  const [DayPhone, setDayPhone] = useState("");
  const [Gender, setGender] = useState("");
  const [DateofBirth, setDateofBirth] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    var data = JSON.stringify({
      user: user,
      password: password,
    });

    var config = {
      method: "post",
      url: "https://trime.om/api/api/cockpit/authUser?token=85708875aa7f8dc7d3d2752bfbce31",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        ////console.log("LOGIN", response.data);
        notyf.success("Login successfull.");
        sessionStorage.setItem("auth", response.data.api_key);
        sessionStorage.setItem("user", response.data.user);
        sessionStorage.setItem(
          "name",
          response.data.First_Name + response.data.Last_Name
        );
        sessionStorage.setItem("id", response.data._id);
        sessionStorage.setItem("TID", response.data.TID);

        window.location.href = "/";
        //window.location.reload();
        //navigate(-1, {replace: true , reload:true});
       
        // setTimeout(() => window.location.reload(), 1000);
        // navigate(-1, {replace: true , reload:true})

        setUser("");
        setPassword("");
      })
      .catch(function (error) {
        ////console.log(error);
        notyf.error(error);
        setUser("");
        setPassword("");
      });
  };
  //
  

  if (!post) return null;

  return (
    <>
      {isLoading ? (
        <div
          className="loading loading-lg"
          style={{
            position: "fixed",
            zIndex: "10",
            left: "0",
            right: "0",
            top: "0",
            bottom: "0",
            backgroundColor: "white",
          }}
        ></div>
      ) : (
        <div></div>
      )}

      {/* <picture className="Page">
        <source
          media="(min-width: 1281px)"
          srcSet={`https://trime.om/api/storage/uploads` + post.image.path}
        />
        <source
          media="(min-width: 601px)"
          srcSet={`https://trime.om/api/storage/uploads` + post.image_tab.path}
        />
        <source
          media="(min-width: 600px)"
          srcSet={
            `https://trime.om/api/storage/uploads` + post.image_mobile.path
          }
        />
        <img
          src={`https://trime.om/api/storage/uploads` + post.image_mobile.path}
          alt={post.title}
          title={post.title}
          className="PageIMG"
          loading="lazy"
        />
      </picture> */}

      <div
        className="BlackShade"
        style={{
          position: "relative",
          top: "0",
          bottom: "0",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "2",
          paddingTop: "90px",
          paddingBottom: "55px",
        }}
      >
        <Tabs>
          <Link to="/" className="FlotX">
            X
          </Link>
          <TabList className="Taba">
            <Tab>Login</Tab>
            
          </TabList>
          <TabPanel>
            {/* LOGIN */}
            <form id="Login" className="Login" onSubmit={handleSubmit}>
              <input
                type="email"
                id="user"
                name="user"
                value={user}
                onChange={(event) => setUser(event.target.value)}
                required
                placeholder="Email"
              />

              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                required
                placeholder="Password"
              />

              <button type="submit">Login</button>
            </form>
            <div style={{
              addingTop: "10px"
            }}>Don't have an account? Please <Link to="/register" style={{
              textDecoration: "underline"
            }}>register</Link> here.</div>
            {/*  */}
          </TabPanel>
        
        </Tabs>
      </div>
    </>
  );
};
export default Page;
