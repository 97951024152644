import { Route, Routes } from "react-router-dom";

import React, { useState, useEffect } from "react";

import Header from "./Header";
import Footer from "./Footer";

import Login from "./Pages/LoginRegister/Login";
import Register from "./Pages/LoginRegister/Register";

import Account from "./Pages/Account";

import Home from "./Pages/Home";
import Page from "./Pages/Pages";
import Event from "./Pages/Event";
import Events from "./Pages/Events";
import EventsSingle from "./Pages/Events/single";

import ContactUs from "./Pages/ContactUs";

import Gallery from "./Pages/Gallery";
import GallerySingle from "./Pages/Gallery/Single";
import GallerySingleIMAGES from "./Pages/Gallery/Images";

import Shop from "./Pages/Shop";
import Cart from "./Pages/Cart";
import Volunteers from "./Pages/Volunteers";
import Results from "./Pages/Results";

import NotFoundPage from "./Pages/NotFoundPage";

function App() {
  const authedUser = sessionStorage.getItem("user");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/singletons/get/settings?token=85708875aa7f8dc7d3d2752bfbce31"
      );
      await response.json();
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div className="App">
      <Header />
      <div
        style={{
          minHeight: "calc(100vh - 135px)",
        }}
      >
        {isLoading ? (
          <div
            className="loading loading-lg"
            style={{
              position: "fixed",
              zIndex: "10",
              left: "0",
              right: "0",
              top: "0",
              bottom: "0",
              backgroundColor: "white",
            }}
          ></div>
        ) : (
          <div></div>
        )}

        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/account" element={<Account />} />
          <Route path="/shop" element={<Shop />} />

          <Route path="/cart" element={<Cart />} />
          <Route path="/volunteers" element={<Volunteers />} />
          <Route path="/results" element={<Results />} />

          <Route path="/contact-us" element={<ContactUs />} />

          <Route path="/" element={<Home />} />
          <Route path="/:slug" element={<Page />} />
          <Route path="/events" element={<Event />} />
          <Route path="/events/:slug/:id" element={<EventsSingle />} />
          <Route path="/events/:slug/" element={<Events />} />
          <Route path="/gallery/" element={<Gallery />} />
          <Route path="/gallery/:slug" element={<GallerySingle />} />
          <Route
            path="/gallery/:slug/:sub_slug/:gallery_title_slug"
            element={<GallerySingleIMAGES />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
