import { useParams } from "react-router-dom";

import React, { useState, useEffect } from "react";
import "./Cart.css";

import CartShop_ViewDel from "../../Components/CartShop_ViewDel";
import CartIMG_ViewDel from "../../Components/CartIMG_ViewDel";
import CartEvent_ViewDel from "../../Components/CartEvent_ViewDel";
import CartOffer_ViewDel from "../../Components/CartOffer_ViewDel";

import CAL from "../../Components/Cal";

const authedUser = sessionStorage.getItem("user");
const authedStore = sessionStorage.getItem("auth");

const Page = () => {
  let params = useParams();
  ////console.log(params);
  const [post, setPost] = React.useState();
  const [shop, setShop] = React.useState();
  const [image, setImage] = React.useState();
  const [event, setEvent] = React.useState();
  const [offers, setOffers] = React.useState();

  // ACCOUNT
  if (!authedStore) {
    window.location.href = "/login";
  }
  //

  useEffect(() => {
    // PAGE
    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/pages/cart?token=85708875aa7f8dc7d3d2752bfbce31",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            filter: { title_slug: "cart" },
          }),
        }
      );
      await response
        .json()

        .then(function (response) {
          ////console.log("Page Data", response.entries[0]);
          setPost(response.entries[0]);
        });
    };
    fetchData();

    // SHOPPS
    const fetchDataSHOP = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/cart_shop",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Cockpit-Token": authedStore,
          },
          // body: JSON.stringify({
          //   filter: { user: authedUser },
          // }),
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log("cart_shop", response.entries);
          setShop(response.entries);
        });
    };
    fetchDataSHOP();
    //

    // Images
    const fetchDataIMG = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/cart_image",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Cockpit-Token": authedStore,
          },
          // body: JSON.stringify({
          //   filter: { user: authedUser },
          // }),
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log("cart_image", response.entries);
          setImage(response.entries);
        });
    };
    fetchDataIMG();
    //

    // SignUP
    const fetchDataSignUP = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/signups",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Cockpit-Token": authedStore,
          },
          // body: JSON.stringify({
          //   filter: { Email: authedUser },
          // }),
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log("event", response.entries);
          setEvent(response.entries);
        });
    };
    fetchDataSignUP();
    //

    // Offers
    const fetchDataOffers = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/offerscart",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Cockpit-Token": authedStore,
          },
          // body: JSON.stringify({
          //   filter: { Email: authedUser },
          // }),
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log("offers", response.entries);
          setOffers(response.entries);
        });
    };
    fetchDataOffers();
    //
  }, []);

  if (!post) return null;
  if (!shop) return null;
  if (!image) return null;
  if (!event) return null;
  if (!offers) return null;

  return (
    <>
      <picture className="Page">
        <source
          media="(min-width: 1281px)"
          srcSet={`https://trime.om/api/storage/uploads` + post.image.path}
        />
        <source
          media="(min-width: 601px)"
          srcSet={`https://trime.om/api/storage/uploads` + post.image_tab.path}
        />
        <source
          media="(min-width: 600px)"
          srcSet={
            `https://trime.om/api/storage/uploads` + post.image_mobile.path
          }
        />
        <img
          src={`https://trime.om/api/storage/uploads` + post.image_mobile.path}
          alt={post.title}
          title={post.title}
          className="PageIMG"
          loading="lazy"
        />
      </picture>
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          className="container grid-xl"
          style={{
            marginTop: "40px",
            marginBottom: "40px",
          }}
        >
          <h5 className="container grid-xl">CART</h5>

          {shop.length || image.length || event.length || offers.length ? (
            <div></div>
          ) : (
            <h5 className="EmptyCart">Cart is Empty</h5>
          )}

          {shop.length ? (
            <div>
              <div
                className="columns"
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                {shop.length &&
                  shop.map((item) => {
                    return (
                      <>
                        <div
                          className="column col-3 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
                          key={item._id}
                          style={{
                            marginTop: "15px",
                            marginBottom: "15px",
                          }}
                        >
                          <CartShop_ViewDel
                            ProductID={item.product_id}
                            RemoveID={item._id}
                          />

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <h5 className="Details">
                              Quantity {item.quantity}
                            </h5>
                            <h5 className="Details">Size {item.size}</h5>
                          </div>
                          <h5
                            className="Details"
                            style={{
                              textAlign: "center",
                              fontWeight: "700",
                              fontSize: "18px",
                            }}
                          >
                            Price {item.price} OMR
                          </h5>
                        </div>
                      </>
                    );
                  })}
              </div>
              <div className="Liner"></div>
            </div>
          ) : (
            <div></div>
          )}
          {image.length ? (
            <div>
              {/* IMAGES */}
              <div
                className="columns"
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                {image.length &&
                  image.map((item) => {
                    return (
                      <>
                        <div
                          className="column col-3 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
                          key={item._id}
                          style={{
                            marginTop: "15px",
                            marginBottom: "15px",
                          }}
                        >
                          <CartIMG_ViewDel
                            ProductID={item.view}
                            RemoveID={item._id}
                          />

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <h5 className="Details">
                              Product ID {item.product_id}
                            </h5>
                          </div>
                          <h5
                            className="Details"
                            style={{
                              textAlign: "center",
                              fontWeight: "700",
                              fontSize: "18px",
                            }}
                          >
                            Price {item.price} OMR
                          </h5>
                        </div>
                      </>
                    );
                  })}
              </div>
              <div className="Liner"></div>
            </div>
          ) : (
            <div></div>
          )}
          {event.length ? (
            <div>
              {/* Events */}
              <div
                className="columns"
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                {event.length &&
                  event.map((item) => {
                    return (
                      <>
                        <div
                          className="column col-3 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
                          key={item._id}
                          style={{
                            marginTop: "15px",
                            marginBottom: "15px",
                          }}
                        >
                          <CartEvent_ViewDel
                            ProductID={item.id}
                            RemoveID={item._id}
                          />

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <h5 className="Details">{item.Event}</h5>
                          </div>
                          <h5
                            className="Details"
                            style={{
                              textAlign: "center",
                              fontWeight: "700",
                              fontSize: "18px",
                            }}
                          >
                            Price {item.Price} OMR
                          </h5>
                        </div>
                      </>
                    );
                  })}
              </div>
              <div className="Liner"></div>
            </div>
          ) : (
            <div></div>
          )}
          {offers.length ? (
            <div>
              {/* Events */}
              <div
                className="columns"
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                {offers.length &&
                  offers.map((item) => {
                    return (
                      <>
                        <div
                          className="column col-3 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
                          key={item._id}
                          style={{
                            marginTop: "15px",
                            marginBottom: "15px",
                          }}
                        >
                          <CartOffer_ViewDel
                            ProductID={item.product_id}
                            RemoveID={item._id}
                            FIMG={item.FIMG}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <h5 className="Details">
                              <b>OFFER ID:</b> {item.offerID}
                            </h5>
                          </div>
                          <h5
                            className="Details"
                            style={{
                              textAlign: "center",
                              fontWeight: "700",
                              fontSize: "18px",
                            }}
                          >
                            Price {item.price} OMR
                          </h5>
                        </div>
                      </>
                    );
                  })}
              </div>
              <div className="Liner"></div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>

      {shop.length || image.length || event.length || offers.length ? (
        <>
        <CAL shop={shop} image={image} offers={offers} event={event} />
        
  </>
      ) : (
        <div></div>
      )}
    </>
  );
};
export default Page;
