import React, { useEffect } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
const notyf = new Notyf();

export default function LoadGallery({ ProductID, Size, Quantity, Price, ID }) {
  const authedStore = sessionStorage.getItem("auth");
  const authedID = sessionStorage.getItem("id");
  const authedUser = sessionStorage.getItem("user");

  const [event, setPost] = React.useState();

  function handleSubmit(e) {
    e.preventDefault();

    var data = JSON.stringify({
      data: {
        user: authedUser,
        product_id: ProductID,
        price: Price,
        quantity: Quantity,
        size: Size,
        _by: authedID,
      },
    });

    var config = {
      method: "post",
      url: "https://trime.om/api/api/collections/save/cart_shop",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": authedStore,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(response.data);
        // REMOVE
        var data = JSON.stringify({
          filter: ID,
        });

        var config = {
          method: "post",
          url: "https://trime.om/api/api/collections/remove/wishlist?token=85708875aa7f8dc7d3d2752bfbce31",
          headers: {
            "Content-Type": "application/json",
          },
          maxRedirects: 0,
          data: data,
        };

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            notyf.success("Your Cart Updated successfully.");
            window.location.reload(false);
          })
          .catch(function (error) {
            console.log(error);
          });

        //
      })
      .catch(function (error) {
        //console.log(error);
      });
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/entry/shop/" +
          ProductID +
          "?token=85708875aa7f8dc7d3d2752bfbce31",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
        }
      );
      await response
        .json()

        .then(function (response) {
          console.log(ProductID, response);
          setPost(response);
        });
    };
    fetchData();
  }, [ProductID]);

  if (!event) return null;

  return (
    <>
      <img
        src={`https://trime.om/api/storage/uploads` + event.image.path}
        alt={event.title}
        title={event.title}
        className="WishLIstIMG"
        loading="lazy"
      />
      <form onSubmit={handleSubmit}>
        <button
          style={{
            background: "transparent",
            border: "1px solid",
            cursor: "pointer",
            zIndex: "1",
          }}
          type="submit"
        >
          ADD TO CART
        </button>
      </form>
    </>
  );
}
