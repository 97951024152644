import React, { useEffect } from "react";


export default function Child({ sponsors_ID }) {
  const [item, setPost] = React.useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/entry/associates/" + sponsors_ID +"?token=85708875aa7f8dc7d3d2752bfbce31",
        {
          method: "get",
          headers: { "Content-Type": "application/json" },
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log("Single ICONS", response);
          setPost(response);
        });
    };
    fetchData();
  }, [sponsors_ID]);

  if (!item) return null;


  return (
    
    <>
    <a href={item.Url} target="_blank">
                    <img
                      src={
                        `https://trime.om/api/storage/uploads` +
                        item.logo.path
                      }
                      alt={item.name}
                      title={item.name}
                      className="EventSliderIMG"
                      loading="lazy"
                    /></a>
    </>
  );
}
