import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";

import "./Shop_Single.css";
import axios from "axios";

import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
const notyf = new Notyf();

export default function Child({ parentToChild }) {
  // SEND To CART
  const authedUser = sessionStorage.getItem("user");
  const authedID = sessionStorage.getItem("id");
  const authedAuth = sessionStorage.getItem("auth");

  //const [user, setuser] = useState("");
  //const [product_id, setproduct_id] = useState("");
  // const [price, setprice] = useState("");
  // const [quantity, setquantity] = useState("");
  // const [sizeX, setSize] = useState();

  const handleSubmit = (event) => {
    // 👇️ prevent page refresh
    event.preventDefault();

    var data = JSON.stringify({
      data: {
        user: authedUser,
        product_id: parentToChild,
        price: value * value2,
        quantity: value2,
        size: age,
        _by: authedID,
      },
    });

    var config = {
      method: "post",
      url: "https://trime.om/api/api/collections/save/cart_shop",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": authedAuth,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(response.data);
        notyf.success("Your request has been successfully submitted.");
        window.location.reload();
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  //   WISHLIST

  const handleSubmitWL = (event) => {
    //  prevent page refresh
    event.preventDefault();

    var data = JSON.stringify({
      data: {
        user: authedUser,
        product_id: parentToChild,
        price: value * value2,
        quantity: value2,
        size: age,
        _by: authedID,
      },
    });

    var config = {
      method: "post",
      url: "https://trime.om/api/api/collections/save/wishlist",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": authedAuth,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(response.data);
        notyf.success("Your request has been successfully submitted.");
        window.location.reload();
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  //
  const [product, setPost] = React.useState();

  //    DropDown Data
  const getInitialState = () => {
    const value = "Select";
    return value;
  };

  const [value, setValue] = useState(getInitialState);
  const [age, setAge] = useState(getInitialState);

  const handleChange = (e) => {
    setValue(e.target.value);

    var index = e.target.selectedIndex;
    // console.log(e.nativeEvent.target[index].text);

    setAge(e.nativeEvent.target[index].text);
  };

  //

  // Quantity
  const getInitialState2 = () => {
    const value2 = "1";
    return value2;
  };

  const [value2, setValue2] = useState(getInitialState2);

  const handleChange2 = (e) => {
    setValue2(e.target.value);
  };
  //

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/entry/shop/" +
          parentToChild +
          "/?token=85708875aa7f8dc7d3d2752bfbce31",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
        }
      );
      await response
        .json()

        .then(function (response) {
          // console.log("Page Data", response);
          setPost(response);
        });
    };
    fetchData();

    //
  }, [parentToChild]);

  if (!product) return null;

  return (
    <div>
      <img
        src={`https://trime.om/api/storage/uploads` + product.image.path}
        alt={product.title}
        title={product.title}
        className="ProductIMG"
        loading="lazy"
      />
      <h5>{product.title}</h5>

      <div className="MakeSelectHalf">
        <div
          class="form-group"
          style={{
            width: "100%",
          }}
        >
          <select class="form-select" value={value} onChange={handleChange}>
            <option value="Select">Select</option>
            {product.price &&
              product.price.map((item) => {
                return (
                  <>
                    <option value={item.value.price}>{item.value.size}</option>
                  </>
                );
              })}
          </select>
        </div>

        {value === "Select" ? (
          <div style={{}}></div>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              className="form-group"
              style={{
                margin: "0",
                paddingLeft: "10px",
                paddingRight: "10px",
                fontWeight: "500",
              }}
            >
              Quantity
            </div>
            <div className="form-group" style={{}}>
              <select
                className="form-select"
                value={value2}
                onChange={handleChange2}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>
        )}
      </div>

      <h5
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margintop: "10px",
          marginBottom: "10px",
        }}
      >
        {value === "Select" ? (
          <div
            style={{
              height: "30px",
            }}
          ></div>
        ) : (
          <>
            <div
              style={{
                fontWeight: "900",
                fontSize: "18px",
              }}
            >
              {value * value2} OMR
            </div>

            {authedUser ? (
              <div>
                <form
                  id="SignUPFrom"
                  className="SignUPForm"
                  onSubmit={handleSubmit}
                >
                  <button className="ShopBtnA" type="submit">
                    ADD TO CART
                  </button>
                </form>

                <form
                  id="SignUPFrom"
                  className="SignUPForm"
                  onSubmit={handleSubmitWL}
                >
                  <button className="ShopBtnB" type="submit">
                    ADD TO WISHLIST
                  </button>
                </form>
              </div>
            ) : (
              <div>
                <form id="SignUPFrom" className="SignUPForm">
                  <Link to="/register" className="ShopBtnA">
                    REGISTER
                  </Link>
                </form>
              </div>
            )}
          </>
        )}

        {/* {`You selected ${value}`} */}
      </h5>
    </div>
  );
}
