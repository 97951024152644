import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

import axios from "axios";
import "./AssociatesSlider.css";

const AssociatesSlider = (props) => {
  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    var data = "";

    var config = {
      method: "get",
      url: "https://trime.om/api/api/collections/get/associates?token=85708875aa7f8dc7d3d2752bfbce31&sort[date]=1",
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        ////console.log("AssociatesSlider", response.data.entries);
        setPost(response.data.entries);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  if (!post) return null;

  return (
    <>
      <div className="AssociatesSlider">
        <h4 className="associatesTitle">Our Associates</h4>
        <Swiper
          className="mySwiperAssociatesSlider"
          slidesPerView={1}
          spaceBetween={30}
          slidesPerGroup={1}
          loop={true}
          loopFillGroupWithBlank={false}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          breakpoints={{
            400: {
              slidesPerView: 1,
              spaceBetween: 30,
              slidesPerGroup: 1,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 30,
              slidesPerGroup: 2,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 30,
              slidesPerGroup: 4,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 30,
              slidesPerGroup: 5,
            },
          }}
        >
          {post &&
            post.map((slider, index) => {
              return (
                <SwiperSlide key={index}>
                    <a href={slider.Url} target="_blank">
                    <img
                      src={
                        `https://trime.om/api/storage/uploads` +
                        slider.logo.path
                      }
                      alt={slider.name}
                      title={slider.name}
                      className="EventSliderIMG"
                      loading="lazy"
                    />
                    </a>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </>
  );
};

export default AssociatesSlider;
