import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Shop.css";

import ShopSingle from "../../Components/Shop_Single";


const Page = () => {
  let params = useParams();
  ////console.log(params);

  const [post, setPost] = React.useState();
  const [shop, setShop] = React.useState();



  useEffect(() => {



    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/pages?token=85708875aa7f8dc7d3d2752bfbce31",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            filter: { title_slug: "shop" },
          }),
        }
      );
      await response
        .json()

        .then(function (response) {
          ////console.log("Page Data", response.entries[0]);
          setPost(response.entries[0]);
        });
    };
    fetchData();

    // SHOPPS
    const fetchDataSHOP = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/shop?token=85708875aa7f8dc7d3d2752bfbce31",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log("PRODUCTS", response.entries);
          setShop(response.entries);
        });
    };
    fetchDataSHOP();
    //
  }, [params.slug]);

  if (!post) return null;

  return (
    <>


      <picture className="Page">
        <source
          media="(min-width: 1281px)"
          srcSet={`https://trime.om/api/storage/uploads` + post.image.path}
        />
        <source
          media="(min-width: 601px)"
          srcSet={`https://trime.om/api/storage/uploads` + post.image_tab.path}
        />
        <source
          media="(min-width: 600px)"
          srcSet={
            `https://trime.om/api/storage/uploads` + post.image_mobile.path
          }
        />
        <img
          src={`https://trime.om/api/storage/uploads` + post.image_mobile.path}
          alt={post.title}
          title={post.title}
          className="PageIMG"
          loading="lazy"
        />
      </picture>

      <div
        style={{
          position: "relative",
        }}
      >
        <div
          className="container grid-xl"
          style={{
            marginTop: "40px",
            marginBottom: "40px",
          }}
        >
          <div className="columns" style={{
            marginLeft: "20px",
            marginRight: "20px"
          }}>
            {shop &&
              shop.map((product, index) => {
                return (
                  <>
                    <div
                      className="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4"
                      key={index}
                    >

                      <ShopSingle parentToChild={product._id}  />
                     

                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
export default Page;
