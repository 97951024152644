import React from "react";
import axios from "axios";
import "./Calendar.css";
import { useState } from "react";

import CalendarMonthView from "react-calendar-month-view";
import moment from "moment";

function CalendarProps() {
  const current = new Date();
  const dateEVE = `${current.getFullYear()}-${
    current.getMonth() + 1
  }-${current.getDate()}`;
  //console.log(date);

  const [info, setPost] = React.useState(null);
  const [event, setPostEve] = React.useState(null);
  const [image, setPostEveIMG] = React.useState(null);
  const [url, setPostEveURL] = React.useState(null);
  const [url2, setPostEveURL2] = React.useState(null);

  //const [date, setDate] = useState([new Date(2022, 10, 1)]);

  React.useEffect(() => {
    var data = "";

    var config = {
      method: "post",
      url: "https://trime.om/api/api/singletons/get/settings?token=85708875aa7f8dc7d3d2752bfbce31",
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(response.data);
        setPost(response.data);
      })
      .catch(function (error) {
        //console.log(error);
      });
    // EVENTS
    var dataEve = "";

    var configEve = {
      method: "get",
      url:
        "https://trime.om/api/api/collections/get/sub_events?token=85708875aa7f8dc7d3d2752bfbce31&sort[date]=1&limit=1&filter[published]=true",
      headers: {},
      data: dataEve,
    };

    axios(configEve)
      .then(function (response) {
        //console.log("SIN EVE", response.data.entries[0].Calendar_Logo.path);
        setPostEve(response.data.entries[0].date);
        //console.log("SIN EVE", response.data.entries[0]);
        setPostEveIMG(response.data.entries[0].Calendar_Logo.path);
        setPostEveURL(response.data.entries[0].event.display);
        setPostEveURL2(response.data.entries[0].title_slug);
      })
      .catch(function (error) {
        //console.log(error);
      });
    //

    //
  }, []);

  // Calender Props HERE
  // {
  //   events && events.map((event, index) => {});
  // }

  function handleSubmit(e) {
    e.preventDefault();
    console.log("You clicked submit.");
  }

  const _renderDay = (day) => {
    const dateX = moment(day);
    const dateY = moment(event);

    const diff = dateX.diff(moment().startOf("day"));
    const diff2 = dateY.diff(moment().startOf("day"));

    if (diff - diff2) {
      return (
        <div
          style={{
            backgroundColor: "transparent",
            boxSizing: "border-box",
            height: "100%",
            width: "100%",
            backgroundImage: "none !important",
            zIndex: "-1",
            display: "none",
          }}
        />
      );
    } else {
      return (
        <>
          <a
            href={`https://trime.om/events/` + url + `/` + url2}
            style={{
              height: "100%",
              width: "100%",
              display: "block",
              cursor: "pointer !important",
              zIndex: "2",
              backgroundColor: "transparent !important",
              //color: "transparent !important",
              cursor: "pointer !important",
            }}
          ></a>
          <div
            style={{
              backgroundColor: "#373737 !important",
              // backgroundColor: "transparent !important",
              backgroundImage:
                "url(" + `https://trime.om/api/storage/uploads` + image + ")",
              backgroundSize: "cover",
              // borderRadius: showDefaultStyles ? 0 : '10%'
              zIndex: "1",
              cursor: "pointer !important",
            }}
          />
        </>
      );
    }
    //
  };

  //

  if (!info) return null;
  //if (!events) return null;

  return (
    <div
      className="container"
      style={{
        position: "relative",
        paddingLeft: "0",
        paddingRight: "0",
        top: "-6px",
      }}
    >
      <div className="GradintCalendar"></div>
      <div className="columns">
        <div
          className="column col-6 col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 MobEffect"
          style={{
            zIndex: "0",
            textAlign: "center",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <div className="Holder">
            <div
              className="LeftH"
              style={{
                color: "white",
              }}
            >
              <div className="SepDiv">Whats</div>
              <div className="SepDiv">on</div>
              <div className="SepDiv">this</div>
              <div className="SepDiv">month</div>
            </div>
            <div className="RightH">
              <div className="calendar-container">
                <CalendarMonthView renderDay={_renderDay} />
              </div>
            </div>
          </div>
        </div>

        <div
          className="column col-6 col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5"
          style={{
            backgroundImage: `url(${
              `https://trime.om/api/storage/uploads` + info.home_event.path
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "30px",
            minHeight: "365px",
          }}
        ></div>
      </div>
    </div>
  );
}

export default CalendarProps;
