import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

import axios from "axios";
import "./Advertising.css";

const Advertising = (props) => {
  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    var data = "";

    var config = {
      method: "get",
      url: "https://trime.om/api/api/singletons/get/advertising?token=85708875aa7f8dc7d3d2752bfbce31",
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        ////console.log("advertising", response.data);
        setPost(response.data);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  if (!post) return null;

  return (
    <>
    <div className="Advertising">
      <Swiper
        direction={"vertical"}
        pagination={{
          clickable: true,
        }}
        freeMode = {"true"}

        modules={[Pagination]}
        className="mySwiperAdvertising"
      >
        {post.advertising &&
          post.advertising.map((slider, index) => {
            return (
              <SwiperSlide key={index}>

                <img src={`https://trime.om/api/storage/uploads` +
                    slider.value.image.path} className="SliderIMGNew" />
                
              </SwiperSlide>
            );
          })}
      </Swiper>
      </div>
    </>
  );
};

export default Advertising;
