import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import SignUP from "../SignUP";

import "./HomeSignUp.css";
import SMILE from "./images/smile.svg";


const authedUser = sessionStorage.getItem("user");

const HomeSignUp = (props) => {
  const [post, setPost] = React.useState(null);

  const current = new Date();
  const date = `${current.getFullYear()}-${
    current.getMonth() + 1
  }-${current.getDate()}`;
  //console.log(date);

  React.useEffect(() => {
    var data = "";

    var config = {
      method: "post",
      url:
        "https://trime.om/api/api/collections/get/sub_events?token=85708875aa7f8dc7d3d2752bfbce31&sort[date]=1&filter[published]=true",
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("HomeSignUp", response.data.entries[0]);
        setPost(response.data.entries[0]);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  if (!post) return null;

  return (
    <>
      <div
        className="container"
        style={{
          position: "relative",
          paddingLeft: "0",
          paddingRight: "0",
          top: "-6px",
        }}
      >
        <div className="Gradint"></div>
        <div className="columns">
          <div
            className="column col-6 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
            style={{
              zIndex: "0",
              color: "white",
              textAlign: "center",
              padding: "30px",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            <div>
              <Link
                title={post.title}
                to={`events/` + post.event.display + `/` + post.title_slug}
              >
                <img
                  src={
                    `https://trime.om/api/storage/uploads` +
                    post.event_logo.path
                  }
                  alt={post.title}
                  title={post.title}
                  className="EventLogoIMG"
                  loading="lazy"
                />
              </Link>
            </div>

            <div>
            <h1 className="DateHolderName">{post.title}</h1>
              <h1 className="DateHolderH">{post.date}</h1>
            </div>
          </div>

          <div
            className="column col-6 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
            style={{
              backgroundImage: `url(${
                `https://trime.om/api/storage/uploads` + post.image_Home.path
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              padding: "30px",
              minHeight: "365px",
            }}
          >
            <div className="SignUPBtn">
              {authedUser ? (

                // Check for Signup url
                
                <div>

{post.signup ? (
            // IF LOAD THIS
            <>
              {/* <a className="btnSignUpPOP2" href={post.signup} target="_blank">
                SIGN UP
              </a> */}
              <a className="btnSignUp" href="#SignUP_Redirect">
              REGISTER
              </a>
            </>
          ) : (
            //
            <a className="btnSignUp" href="#SignUP">
            REGISTER
          </a>
          )}


                  
                </div>
              ) : (
                <div>
                  <Link to="/register" className="btnSignUp">
                  REGISTER
                  </Link>
                </div>
              )}

              <div className="modal" id="SignUP">
                <a className="modal-overlay" href="#close" aria-label="Close">
                  &#8203;
                </a>
                <div className="modal-container" role="document">
                  <div className="modal-header">
                    <a
                      style={{
                        top: "5px",
                        position: "relative",
                      }}
                      className="btn btn-clear float-right"
                      href="#close"
                      aria-label="Close"
                    >
                      &#8203;
                    </a>
                    <div className="modal-title h5">
                      {post.title} <b> ({post.price} OMR)</b>
                      {post.Price_notes ? (
            // IF LOAD THIS
            <>
            <small style={{
                  display: "block",
                  fontSize: "14px"
                }}>({post.Price_notes})</small>
            </>
          ) : (
            //
            <span></span>
          )}
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="content">
                      <SignUP
                        eventName={post.title}
                        price={post.price}
                        id={post._id}
                      />
                    </div>
                  </div>
                  <div className="modal-footer"></div>
                </div>
              </div>

               {/* LatePOPUP */}
          <div className="modal" id="SignUP_Redirect">
            <a className="modal-overlay" href="#close" aria-label="Close">
              &#8203;
            </a>
            <div className="modal-container DARK" role="document">
              <div className="modal-header">
                <a
                  style={{
                    top: "5px",
                    position: "relative",
                    color: "white",
                  }}
                  className="btn btn-clear float-right"
                  href="#close"
                  aria-label="Close"
                >
                  &#8203;
                </a>
                <div
                  className="modal-title h5"
                  style={{
                    color: "#fd682d",
                  }}
                >
                  {post.title}
                </div>
              </div>
              <div className="modal-body">
                <div className="content">
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    WISHING YOU THE BEST OF LUCK
                    <div
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <img
                        src={SMILE}
                        className="IconSizeIMGSMILE"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      After you have signed up
                      <br />
                      feel free to take advantage of our offers
                    </div>
                    <div className="OFFHolder">
                      <div className="H1Holder">
                        HOTEL
                        <br />
                        OFFERS
                      </div>
                      <div className="H2Holder">
                        SEA TOUR
                        <br />
                        OFFERS
                      </div>
                      <div className="H3Holder">
                        LAND TOUR
                        <br />
                        OFFERS
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "40px",
                        display: "inline-block",
                      }}
                    >

<Link to={`/events/`+ post.event.display + `/` + post.title_slug}  style={{
                          top: "5px",
                          position: "relative",
                          color: "#343434",
                          backgroundColor: "white",
                          borderRadius: "10px",
                          paddingTop: "7px",
                          paddingRight: "15px",
                          paddingBottom: "7px",
                          paddingLeft: "15px",
                          marginRight: "5px",
                        }}>
                    SEE OFFERS
                  </Link>


                      <a
                        style={{
                          top: "5px",
                          position: "relative",
                          color: "rgb(255 255 255)",
                          backgroundColor: "#fb692e",
                          borderRadius: "10px",
                          paddingTop: "7px",
                          paddingRight: "15px",
                          paddingBottom: "7px",
                          paddingLeft: "15px",
                          marginLeft: "5px",
                        }}
                        href={post.signup}
                        target="_blank"
                      >
                        CONTINUE TO SIGN UP
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
          {/*  */}

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeSignUp;
