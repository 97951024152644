import React, { useEffect } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
const notyf = new Notyf();

export default function LoadGallery({ ProductID }) {

    const authedStore = sessionStorage.getItem("auth");
    const authedUser = sessionStorage.getItem("id");


    const fetchData = async () => {
        const response = await fetch(
            "https://trime.om/api/api/collections/entry/cart_shop/" +
            ProductID,
            {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    "Cockpit-Token": authedStore,
                },
            }
        );
        await response
            .json()
            .then(function (response) {
                // SAVE INVOCE
                fetch('https://trime.om/api/api/collections/save/invoice', {
                    method: 'post',
                    headers: {
                        "Content-Type": "application/json",
                        "Cockpit-Token": authedStore,
                    },
                    body: JSON.stringify({
                        data: {
                            Type: "Shop",
                            Other: response,
                            _by: authedUser
                        }
                    })
                })
                // SEND EMAIL
                fetch('https://trime.om/api/api/forms/submit/invoice?token=85708875aa7f8dc7d3d2752bfbce31', {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        form: {
                            Type: "Event",
                            Other: response,
                            _by: authedUser
                        }
                    })
                })
                .then(entry => entry.json())
                .then(entry => console.log(entry));
                // 
                // Remove IT
                fetch('https://trime.om/api/api/collections/remove/cart_shop?token=85708875aa7f8dc7d3d2752bfbce31', {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        filter: {
                            _id: ProductID
                        }
                    })
                })

                // 
            });
            //window.location.reload();
    };
    fetchData();

    

   


    return (
        <>
        </>

    );
}

