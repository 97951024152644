import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
  <div
    style={{
      textAlign: "center",
      padding: "20px",
    }}
  >
    <h1>404 - Not Found!</h1>
    <Link to="/">Go Home</Link>
  </div>
);

export default NotFound;
