import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const authedName = sessionStorage.getItem("name");

const Total = (props) => {
  const total_shop = props.shop.reduce(
    (prevValue, currentValue) => prevValue + Number(currentValue.price),
    0
  );
  const total_image = props.image.reduce(
    (prevValue_image, currentValue_image) =>
      prevValue_image + Number(currentValue_image.price),
    0
  );
  const total_offers = props.offers.reduce(
    (prevValue_offers, currentValue_offers) =>
      prevValue_offers + Number(currentValue_offers.price),
    0
  );
  const total_event = props.event.reduce(
    (prevValue_event, currentValue_event) =>
      prevValue_event + Number(currentValue_event.Price),
    0
  );

  const FullPrice = Number(
    total_shop + total_image + total_offers + total_event
  );

  // SEND TO THWANI

  function handleSubmit(e) {
    e.preventDefault();

    const timestamp = Date.now(); // This would be the timestamp you want to format
    //console.log(timestamp);
    const customer_client_id = sessionStorage.getItem("TID"); //
    const customer_client_Name = sessionStorage.getItem("user"); //

    // THWANI SEND

    var axios = require("axios").default;

    const options = {
      method: "POST",
      url: "https://checkout.thawani.om/api/v1/checkout/session",
      headers: {
        "thawani-api-key": "8zK1yZySUrjlgWT0hnwGNMlIGZZJO6",
        "Content-Type": "application/json",
      },

      data: {
        client_reference_id: timestamp,
        //
        products: [
          {
            name: timestamp,
            unit_amount: FullPrice * 1000,
            quantity: 1,
          },
        ],
        //

        success_url: "https://trime.om/results",
        cancel_url: "https://trime.om/cart",
        customer_id: customer_client_id,
        metadata: {
          Customer_name: customer_client_Name,
        },
      },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log("session_id", response.data.data.session_id);
        console.log(response.data.data);

        sessionStorage.setItem("session_id", response.data.data.session_id);

        window.open(
          "https://checkout.thawani.om/pay/" +
            response.data.data.session_id +
            "?key=xcuOniWIHJbRqX1Tel53hTis2TBLju",
          "_self"
        );
      })
      .catch(function (error) {
        console.error(error);
      });

    //
  }

  //
  return (
    <div
      className="container grid-xl"
      style={{
        marginTop: "40px",
        marginBottom: "40px",
      }}
    >
      <form onSubmit={handleSubmit}>
        <button
          class="btn btn-primary"
          style={{
            fontSize: "18px",
            background: "#f57b21",
            color: "white",
            border: "none",
          }}
          type="submit"
        >
          Pay Total {total_shop + total_image + total_offers + total_event} OMR
        </button>
      </form>
      <Link to="/terms-conditions" >
    <small>*Terms and Conditions apply</small>
  </Link>
    </div>
  );
};

export default Total;
