import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import axios from "axios";
import "./SignUp.css";

import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
const notyf = new Notyf();

const authedStore = sessionStorage.getItem("auth");
const authedEmail = sessionStorage.getItem("user");
const authedID = sessionStorage.getItem("id");


const SignUp = (props) => {

  let params = useParams();

  const [post, setPost] = React.useState();

  const [Are_you_Signing_up_For, setAre_you_Signing_up_For] = useState("Yourself");

  const [No_of_Minors, setNo_of_Minors] = useState("");

  const [Emergency_Contact_Name, setEmergency_Contact_Name] = useState("");
  const [Emergency_Contact, setEmergency_Contact] = useState("");
  const [Medical_Conditions, setMedical_Conditions] = useState("");
  const [Reason, setReason] = useState("");
  const [Are_You_Taking_Any_Medications, setAre_You_Taking_Any_Medications] =
    useState("");
  const [
    Do_You_Have_Any_Hypersensitivities,
    setDo_You_Have_Any_Hypersensitivities,
  ] = useState("");
  const [Tell_Your_Story, setTell_Your_Story] = useState("");
  const [Occupation, setOccupation] = useState("");
  const [Employer, setEmployer] = useState("");

  const [Email_Communication, setEmail_Communication] = useState();
  const [Email_Communication_1, setEmail_Communication_1] = useState();
  const [Email_Communication_2, setEmail_Communication_2] = useState();

  const [Minors_info, setMinors_info] = useState();


  


  const [distance, setDistance] = useState();

  // const [Price, setPrice] = useState();


  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/sub_events/?token=85708875aa7f8dc7d3d2752bfbce31&filter[_id]=" + props.id,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            //filter: { title_slug: params.id },
            //filter: { _id: props.id },
          }),
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log("Event Single Home", response.entries[0]);
          setPost(response.entries[0]);
        });


    };
    fetchData();
  }, [params.id]);

  if (!post) return null;







  const handleSubmit = (event) => {
    // 👇️ prevent page refresh
    event.preventDefault();

    var data = JSON.stringify({
      data: {
        Email: authedEmail,
        Are_you_Signing_up_For: Are_you_Signing_up_For,
        Event: props.eventName,
        No_of_Minors: No_of_Minors,

        Emergency_Contact_Name: Emergency_Contact_Name,
        Emergency_Contact: Emergency_Contact,
        Medical_Conditions: Medical_Conditions,
        Reason: Reason,
        Are_You_Taking_Any_Medications: Are_You_Taking_Any_Medications,
        Do_You_Have_Any_Hypersensitivities: Do_You_Have_Any_Hypersensitivities,
        Tell_Your_Story: Tell_Your_Story,
        Occupation: Occupation,
        Employer: Employer,
        Email_Communication: Email_Communication,
        Email_Communication_1: Email_Communication_1,
        Email_Communication_2: Email_Communication_2,
        Price: props.price,
        id: props.id,
        distance: distance,
        _by: authedID,
        Minors_info:Minors_info

      },
    });

    var config = {
      method: "post",
      url: "https://trime.om/api/api/collections/save/signups",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": authedStore

      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        ////console.log(response.data);
        notyf.success("Your request has been successfully submitted.");
        //window.location.reload();
        window.location.href = "/cart";
        // setEmail("");
        // setAre_you_Signing_up_For("");
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  return (
    <>
      {authedEmail ? (



        <div>
          {/* Form */}

          <form id="SignUPFrom" className="SignUPForm" onSubmit={handleSubmit}>
            <h1
              style={{
                textTransform: "uppercase",
              }}
            >
              Signup Form 
            </h1>

            <div className="form-group">
              <label className="form-label">Are you Signing up For</label>

              <select
                id="setAre_you_Signing_up_For"
                className="form-select"
                onChange={(event) =>
                  setAre_you_Signing_up_For(event.target.value)
                }
              >
                <option value="Yourself">Yourself</option>
                <option value="A Minor">A Minor</option>
                <option value="Both">Both</option>
              </select>

              <h5
                style={{
                  fontSize: "14px",
                  marginTop: "10px",
                  color: "red",
                  textTransform: "uppercase",
                }}
              >
                To avoid Duplicate registrations each applicants must sign via
                own email address.
              </h5>
            </div>


            {
      Are_you_Signing_up_For == "Yourself" ?  <div></div> : <div>
        <div className="form-group">
              <label className="form-label">No. of Minors</label>

              <select
                id="setAre_you_Signing_up_For"
                className="form-select"
                onChange={(event) => setNo_of_Minors(event.target.value)}
              >
                <option>Choose an option</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>


            <div className="form-group">
  <label className="form-label" for="input-example-3">Minors Informations (Add Name, Age and Sex in one line each)</label>
  <textarea  value={Minors_info}
                onChange={(event) =>
                  setMinors_info(event.target.value)
                } className="form-input" id="input-example-3" placeholder="Minors Informations (Add Name, Age and Sex in one line each)" rows="3"></textarea>
</div>



            
      </div>
    }



 




            <div className="form-group">
              <label className="form-label">Distance</label> 




              <select
                id="Emergency_Contact_Name"
                name="Emergency_Contact_Name"
                className="form-select"
                onChange={(event) => setDistance(event.target.value)}
              >

                <option>Choose an option</option>

                {post.distance_show_on_signup_form &&
                  post.distance_show_on_signup_form.map((data, index) => {
                    return (
                      <option value={data.value.distance + ` ` + data.value.value}>{data.value.distance} {data.value.value}</option>
                    );
                  })}

              </select>

              




            </div>


            <div className="form-group">
              <label className="form-label">Emergency Contact Name</label>

              <input
                className="form-input"
                type="text"
                id="Emergency_Contact_Name"
                name="Emergency_Contact_Name"
                value={Emergency_Contact_Name}
                onChange={(event) =>
                  setEmergency_Contact_Name(event.target.value)
                }
                required
                placeholder=""
              />
            </div>

            <div className="form-group">
              <label className="form-label">Emergency Contact</label>

              <input
                className="form-input"
                type="number"
                id="Emergency Contact"
                name="Emergency Contact"
                value={Emergency_Contact}
                onChange={(event) => setEmergency_Contact(event.target.value)}
                required
                placeholder=""
              />
            </div>

            <div className="form-group">
              <h4
                style={{
                  fontWeight: "600",
                  marginTop: "25px",
                  textTransform: "uppercase",
                }}
              >
                Medical Conditions
              </h4>

              <h5
                style={{
                  fontSize: "14px",
                  marginTop: "10px",
                  color: "red",
                }}
              >
                MEDICAL INFORMATION IS REQUESTED FOR THE PURPOSE OF MEDICAL
                PERSONNEL PROVIDING YOU WITH ANY NECESSARY MEDICAL TREATMENT
              </h5>

              <select
                id="Medical_Conditions"
                className="form-select"
                onChange={(event) => setMedical_Conditions(event.target.value)}
              >
                <option>Choose an option</option>
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>


            {Medical_Conditions == "Yes" &&




              <div className="form-group">
                <label className="form-label">Reason</label>

                <input
                  className="form-input"
                  type="text"
                  id="Reason"
                  name="Reason"
                  value={Reason}
                  onChange={(event) => setReason(event.target.value)}
                  required
                  placeholder=""
                />
              </div>

            }

            <div className="form-group">
              <label className="form-label">
                Are You Taking Any Medications
              </label>

              <select
                id="Medical_Conditions"
                className="form-select"
                onChange={(event) => setAre_You_Taking_Any_Medications(event.target.value)}
              >
                <option>Choose an option</option>
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>


              {/* <input
                className="form-input"
                type="text"
                id="Are_You_Taking_Any_Medications"
                name="Are_You_Taking_Any_Medications"
                value={Are_You_Taking_Any_Medications}
                onChange={(event) =>
                  setAre_You_Taking_Any_Medications(event.target.value)
                }
                required
                placeholder=""
              /> */}
            </div>

            <div className="form-group">
              <label className="form-label">
                Do You Have Any Hypersesitivities
              </label>

              <select
                id="Are_You_Taking_Any_Medications"
                className="form-select"
                onChange={(event) =>
                  setDo_You_Have_Any_Hypersensitivities(event.target.value)
                }
              >
                <option>Choose an option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="form-group">
              <label className="form-label">Tell Your Story</label>

              <textarea
                className="form-input"
                type="textarea"
                id="Tell_Your_Story"
                name="Tell_Your_Story"
                value={Tell_Your_Story}
                onChange={(event) => setTell_Your_Story(event.target.value)}
                required
                placeholder=""
                rows="4"
                cols="50"
              />
            </div>

            <div className="form-group">
              <label className="form-label">Occupation</label>

              <input
                className="form-input"
                type="text"
                id="Occupation"
                name="Occupation"
                value={Occupation}
                onChange={(event) => setOccupation(event.target.value)}
                required
                placeholder=""
              />
            </div>

            <div className="form-group">
              <label className="form-label">Employer</label>

              <input
                className="form-input"
                type="text"
                id="Employer"
                name="Employer"
                value={Employer}
                onChange={(event) => setEmployer(event.target.value)}
                required
                placeholder=""
              />
            </div>

            <div className="form-group">
              <h4
                style={{
                  fontWeight: "600",
                  marginTop: "25px",
                  textTransform: "uppercase",
                }}
              >
                Email Communication
              </h4>

              <h5
                style={{
                  fontSize: "14px",
                  marginTop: "10px",
                  color: "red",
                }}
              >
                you will be sent emails regrading the event you are registerd
                for. Please opt in for these additional communications
                andspecial offers.
              </h5>

              <fieldset>
                <input
                  type="checkbox"
                  value="TRIME RACES: Registation Alerts, News and Informations"
                  name="Email_Communication"
                  onChange={(event) =>
                    setEmail_Communication(event.target.value)
                  }
                />{" "}
                TRIME RACES: Registation Alerts, News and Informations
              </fieldset>

              <fieldset>
                <input
                  type="checkbox"
                  value="TRIME RACES: Registation Alerts, News and Informations"
                  name="Email_Communication_1"
                  onChange={(event) =>
                    setEmail_Communication_1(event.target.value)
                  }
                />{" "}
                Inside Tracks: Be First to Know all bout Event Announcements and
                Update
              </fieldset>

              <fieldset>
                <input
                  type="checkbox"
                  value="TRIME RACES: Registation Alerts, News and Informations"
                  name="Email_Communication_2"
                  onChange={(event) =>
                    setEmail_Communication_2(event.target.value)
                  }
                />{" "}
                Store: Access the Latest and Greatest, Exclusive Sales, New
                Arrival and More.
              </fieldset>
            </div>

            <div>
              <button className="BUTSignUP" type="submit">
                ADD TO CART
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div>
          <Link to="/register" className="btnSignUp">
            REGISTER
          </Link>
        </div>
      )}
    </>
  );
};

export default SignUp;
