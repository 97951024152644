import { useParams, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Results.css";

import InvoiceShop from "../../Components/Invoice/Shop";
import InvoiceImage from "../../Components/Invoice/Image";
import InvoiceEvent from "../../Components/Invoice/Event";
import InvoiceOffers from "../../Components/Invoice/Offers";



import error from "./images/error-svgrepo-com.svg";
import success from "./images/success-svgrepo-com.svg";

const authedUser = sessionStorage.getItem("user");
const authedStore = sessionStorage.getItem("auth");
const authedSession_id = sessionStorage.getItem("session_id");

const Page = () => {
  let params = useParams();
  ////console.log(params);
  const [responce, setResponce] = React.useState();
  const [shop, setShop] = React.useState();
  const [image, setImage] = React.useState();
  const [event, setEvent] = React.useState();
  const [offers, setOffers] = React.useState();

  // ACCOUNT
  if (!authedStore) {
    window.location.href = "/login";
  }
  //




  useEffect(() => {

    // SHOPPS
    const fetchDataSHOP = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/cart_shop",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Cockpit-Token": authedStore,
          },
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log("cart_shop", response.entries);
          setShop(response.entries);
        });
    };
    fetchDataSHOP();

    // Images
    const fetchDataIMG = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/cart_image",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Cockpit-Token": authedStore,
          },
          // body: JSON.stringify({
          //   filter: { user: authedUser },
          // }),
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log("cart_image", response.entries);
          setImage(response.entries);
        });
    };
    fetchDataIMG();
    //

    // SignUP
    const fetchDataSignUP = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/signups",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Cockpit-Token": authedStore,
          },
          // body: JSON.stringify({
          //   filter: { Email: authedUser },
          // }),
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log("event", response.entries);
          setEvent(response.entries);
        });
    };
    fetchDataSignUP();
    //

    // Offers
    const fetchDataOffers = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/offerscart",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Cockpit-Token": authedStore,
          },
          // body: JSON.stringify({
          //   filter: { Email: authedUser },
          // }),
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log("offers", response.entries);
          setOffers(response.entries);
        });
    };
    fetchDataOffers();
    //

    // GET SESSTION
    var axios = require("axios").default;

    var options = {
      method: "GET",
      url:
        "https://checkout.thawani.om/api/v1/checkout/session/" +
        authedSession_id,
      headers: {
        "Content-Type": "application/json",
        "thawani-api-key": "8zK1yZySUrjlgWT0hnwGNMlIGZZJO6",
      },
    };

    axios
      .request(options)
      .then(function (response) {
        //console.log("ThawaniResponce", response.data.data.payment_status);
        setResponce(response.data.data.payment_status);
      })
      .catch(function (error) {
        console.error(error);
      });
    //


    //
  }, []);


  if (!responce) return null;
  if (!shop) return null;
  if (!image) return null;
  if (!event) return null;
  if (!offers) return null;


  return (
    <>


      {/* Check Responce */}
      <div className="container grid-xl Responce">
        {responce === "paid" ?
          <div>
            <img
              src={success}
              className="IconSizeResults"
              loading="lazy"
            />
            <h3>Payment Successful.</h3>

            <Link
              to="/account"
              className="btnSignUpPOP2"
              style={{
                marginLeft: "0px",
                textTransform: "uppercase"
              }}
            >
              go to account
            </Link>
            <p>Please don't refresh the page, generating invoices</p>


            {/* Crete Invoice */}

            {shop.length ? (
              <div>
                {shop.length &&
                  shop.map((item) => {
                    return (
                      <>
                        <InvoiceShop ProductID={item._id} />
                      </>
                    );
                  })}
              </div>
            ) : (
              <div></div>
            )}


            {image.length ? (
              <div>
                {image.length &&
                  image.map((item) => {
                    return (
                      <>
                        <InvoiceImage ProductID={item._id} />
                      </>
                    );
                  })}
              </div>
            ) : (
              <div></div>
            )}

            {event.length ? (
              <div>
                {event.length &&
                  event.map((item) => {
                    return (
                      <>
                        <InvoiceEvent ProductID={item._id} />
                      </>
                    );
                  })}
              </div>
            ) : (
              <div></div>
            )}

            {offers.length ? (
              <div>
                {offers.length &&
                  offers.map((item) => {
                    return (
                      <>
                        <InvoiceOffers ProductID={item._id} />
                      </>
                    );
                  })}
              </div>
            ) : (
              <div></div>
            )}
            {/* REDIRECT */}
            {
              // redirect to google after 5 seconds
              window.setTimeout(function () {
                window.location.href = '/account';
              }, 3000)
            }
          </div>
          : <div></div>}
        {responce === "unpaid" ?
          <div>
            <img
              src={error}
              className="IconSizeResults"
              loading="lazy"
            />
            <h3>your payment unsuccessful.</h3>
            <Link
              to="/cart"
              className="btnSignUpPOP2"
              style={{
                marginLeft: "0px",
                textTransform: "uppercase"
              }}
            >
              Back to Cart
            </Link>


          </div>
          : <div></div>}
        {responce === "cancelled" ?
          <div>
            <img
              src={error}
              className="IconSizeResults"
              loading="lazy"
            />
            <h3>your payment has been cancelled.</h3>
            <Link
              to="/cart"
              className="btnSignUpPOP2"
              style={{
                marginLeft: "0px",
                textTransform: "uppercase"
              }}
            >
              Back to Cart
            </Link>
          </div>
          : <div></div>}
      </div>
      {/*  */}
    </>
  );
};
export default Page;
