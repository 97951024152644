import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

import Avatar from "./images/user-svgrepo-com.svg";

import axios from "axios";
import "./TestimonialSlider.css";

const TestimonialSlider = (props) => {
  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    var data = "";

    var config = {
      method: "get",
      url: "https://trime.om/api/api/collections/get/testimonial?token=85708875aa7f8dc7d3d2752bfbce31",
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        ////console.log("TestimonialSlider", response.data.entries);
        setPost(response.data.entries);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  if (!post) return null;

  return (
    <>
      <div className="TestimonialSlider">
        <h4 className="associatesTitle">Testimonials</h4>
        <Swiper
          className="mySwiperTestimonialSlider"
          slidesPerView={1}
          spaceBetween={30}
          slidesPerGroup={1}
          loop={true}
          loopFillGroupWithBlank={false}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          breakpoints={{
            400: {
              slidesPerView: 1,
              spaceBetween: 30,
              slidesPerGroup: 1,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 30,
              slidesPerGroup: 2,
            },
            841: {
              slidesPerView: 3,
              spaceBetween: 30,
              slidesPerGroup: 3,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 30,
              slidesPerGroup: 4,
            },
          }}
        >
          {post &&
            post.map((slider, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="HolderTestimonial">



                    {slider.image ? (
                      <>
                        <img src={`https://trime.om/api/storage/uploads` + slider.image.path}  className="ThumbIMGAvatar"  title={slider.title}/>
                      </>
                    ) : (
                      //
                      <>
                     <img
                        src={Avatar}
                        className="ThumbIMGAvatar"
                        loading="lazy"
                        title={slider.title}
                      />

                      </>
                    )}



                    <h4>{slider.title}</h4>
                    <h5>{slider.event}</h5>

                    <div
                      dangerouslySetInnerHTML={{ __html: slider.content }}
                    />

                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </>
  );
};

export default TestimonialSlider;
