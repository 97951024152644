import React from "react";

import axios from "axios";

import { Link } from "react-router-dom";

import Menu from "../Components/Menu/index";

import MenuSub from "../Components/Menu/sub";
import MenuSubEvents from "../Components/Menu/events";
import MenuSubEventsMobile from "../Components/Menu/eventsMobile";

import { ReactComponent as Cart } from "./images/cart-o-svgrepo-com.svg";

import "./Header.css";

const Header = (props) => {
  const authedStore = sessionStorage.getItem("auth");
  const authedUser = sessionStorage.getItem("user");

  ////console.log(authedStore);

  



  const [info, setPost] = React.useState(null);
  const [cart, setCart] = React.useState(null);
  const [cart2, setCart2] = React.useState(null);
  const [cart3, setCart3] = React.useState(null);
  const [cart4, setCart4] = React.useState(null);

  React.useEffect(() => {
    var data = "";

    var config = {
      method: "post",
      url: "https://trime.om/api/api/singletons/get/settings?token=85708875aa7f8dc7d3d2752bfbce31",
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setPost(response.data);
      })
      .catch(function (error) {
        //console.log(error);
      });

    // LOAD CART
    var data2 = "";
    var config2 = {
      method: "get",
      url: "https://trime.om/api/api/collections/get/cart_shop",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": authedStore,
      },
      data: data2,
    };
    axios(config2)
      .then(function (response) {
        setCart(response.data.total);
      })
      .catch(function (error) {});
    //
    // LOAD CART
    var data3 = "";
    var config3 = {
      method: "get",
      url: "https://trime.om/api/api/collections/get/cart_image",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": authedStore,
      },
      data: data3,
    };
    axios(config3)
      .then(function (response) {
        setCart2(response.data.total);
      })
      .catch(function (error) {});
    //
    // LOAD CART
    var data4 = "";
    var config4 = {
      method: "get",
      url: "https://trime.om/api/api/collections/get/signups",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": authedStore,
      },
      data: data4,
    };
    axios(config4)
      .then(function (response) {
        setCart3(response.data.total);
      })
      .catch(function (error) {});
    //
    // LOAD CART
    var data5 = "";
    var config5 = {
      method: "get",
      url: "https://trime.om/api/api/collections/get/offerscart",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": authedStore,
      },
      data: data5,
    };
    axios(config5)
      .then(function (response) {
        setCart4(response.data.total);
      })
      .catch(function (error) {});
    //
  }, []);

  if (!info) return null;
  //if (!cart) return null;

  return (
    <>
      <div
        className="container"
        style={{
          zIndex: "15",
          position: "relative",
        }}
      >
        <div
          className="columns"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="column col-2 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-2">
            <div
              className="SiteLogo"
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
            >
              <Link to="/" className="site-title" key="HomeLogo">
                <img
                  className="SiteLogo"
                  src={
                    `https://trime.om/api/storage/uploads` + info.site_logo.path
                  }
                  alt={info.site_name}
                  title={info.site_name}
                  height="100"
                  width="150"
                />
              </Link>
            </div>
          </div>
          <div className="column col-10 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 text-right Menu">
            <div className="MenuHolder hide-lg">
              {info.top_menu &&
                info.top_menu.map((person, index) => {
                  return (
                    <div className="popover popover-bottom">
                      <Menu
                        key={index}
                        className="btn btn-primary"
                        childToParent={
                          person.value.top_page && person.value.top_page._id
                        }
                      />
                      {/* Sub */}

                      <div className="popover-container">
                        {person.value.sub_page &&
                          person.value.sub_page.map((task, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  backgroundColor: "#fbbaa0bf",
                                  padding: "5px 20px",
                                  lineHeight:"1"
                                }}
                              >
                                <div className="Sub  hide-lg" key={index}>
                                  <MenuSub childToParentSub={task._id} />
                                </div>
                                <div className="Sub  show-lg" key={index}>
                                  <MenuSub childToParentSub={task._id} />
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      {/* Sub Events */}
                      <div className="popover-container">
                        {person.value.sub_page_events &&
                          person.value.sub_page_events.map((events, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  backgroundColor: "#fbbaa0bf",
                                  padding: "5px 20px",
                                }}
                              >
                                <div className="Sub" key={index}>
                                  <MenuSubEvents
                                    childToParentSubEvets={events._id}
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      {/*  */}
                    </div>
                  );
                })}

<a href={`http://trime.om/api/storage/uploads`+info.results.path} target="_blank" className="LoginReg-title">Results</a>


                

              {authedStore ? (
                <div
                  className="popover popover-bottom"
                  style={{
                    marginLeft: "20px",
                  }}
                >
                  <Link to="/account" className="LoginReg-title">
                    MY ACCOUNT
                  </Link>

                  <Link to="/cart" className="LoginReg-title">
                    <figure
                      className="avatar badge"
                      data-badge={cart + cart2 + cart3 + cart4}
                      style={{
                        marginRight: "20px",
                      }}
                    >
                      <Cart />
                    </figure>
                  </Link>
                </div>
              ) : (
                <div
                  className="popover popover-bottom"
                  style={{
                    marginLeft: "20px",
                  }}
                >
                  <Link to="/login" className="LoginReg-title">
                    LOGIN
                  </Link>
                  <Link to="/register" className="LoginReg-title">
                    REGISTER
                  </Link>
                </div>
              )}
            </div>

            

            <div className="show-lg">
              <div className="off-canvas">
                <a
                  className="off-canvas-toggle btn btn-link btn-action"
                  href="#sidebar-id"
                  title="Trime Menu"
                >
                  <i className="icon icon-menu"></i>
                </a>

                 
                <div id="sidebar-id" className="off-canvas-sidebar"  onClick={() => window.location.reload()}>
                  <div
                    className="SiteLogo text-center m-1"
                    style={{
                      paddingTop: "10px",
                    }}
                  >
                    
                    <Link  to="/" className="site-title">
                      <img
                        className="SiteLogo"
                        src={
                          `https://trime.om/api/storage/uploads` +
                          info.site_logo.path
                        }
                        alt={info.site_name}
                        title={info.site_name}
                        height="100"
                        width="150"
                      />
                    </Link>
                  </div>

                  <div className="CenterME">
                    {authedStore ? (
                      <Link to="/cart" className="LoginReg-title">
                        <figure
                          className="avatar badge"
                          data-badge={cart + cart2 + cart3 + cart4}
                          style={{
                            marginRight: "20px",
                          }}
                        >
                          <Cart />
                        </figure>
                      </Link>
                    ) : (
                      <div></div>
                    )}
                  </div>

                  {authedStore ? (
                    <div className="menu text-left">
                      <Link
                        style={{
                          color: "black",
                          fontSize: "18px",
                        }}
                        to="/account"
                      >
                        MY ACCOUNT
                      </Link>
                    </div>
                  ) : (
                    <div className="menu text-left">
                      <Link
                        style={{
                          color: "black",
                          fontSize: "18px",
                        }}
                        to="/login"
                      >
                        LOGIN
                      </Link>
                    </div>
                  )}

<a href={`http://trime.om/api/storage/uploads`+info.results.path}  target="_self" className="menu text-left">Results</a>





                  {info.top_menu &&
                    info.top_menu.map((person, index) => {
                      return (
                        <div className="menu text-left" key={index}>
                          <Menu
                            className="btn btn-primary"
                            childToParent={
                              person.value.top_page && person.value.top_page._id
                            }
                            key={index}
                          />
                          {/* Sub */}
                          {person.value.sub_page &&
                            person.value.sub_page.map((task, index) => {
                              return (
                                <div key={index}>
                                  <div className="Sub" key={index}>
                                    <MenuSub
                                      childToParentSub={task._id}
                                      key={index}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          {/* Sub Events */}



                          {person.value.sub_page_events &&
                            person.value.sub_page_events.map(
                              (events, index) => {
                                return (
                                  <div className="Sub" key={index}>
                                    <MenuSubEventsMobile
                                      childToParentSubEvets={events._id}
                                      key={index}
                                    />
                                  </div>
                                );
                              }
                            )}
                          {/*  */}
                        </div>
                      );
                    })}
                </div>

                <a className="off-canvas-overlay" href="#close"></a>
              </div>
            </div>



          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
