import React from "react";
import axios from "axios";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

import SubEvents from "../../Components/Menu/eventsSUB";


export default function Child({ childToParentSubEvets }) {
  const baseURL =
    "https://trime.om/api/api/collections/entry/events/" +
    childToParentSubEvets +
    "?token=85708875aa7f8dc7d3d2752bfbce31";

  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    axios.get(baseURL).then((response) => {
      setPost(response.data);
      //console.log("EVENTS MENU",response.data);
    });
  }, []);

  if (!post) return null;

  return (
    <>
      <CustomLink

        className="EventSub"
        title={post.title}
        to={`/events/` + post.title_slug}
      >
        <div className="popover popover-right"  key={post.title_slug}>
          {post.title}
     
      
          {
      post.title_slug === "trime" ?   <div className="popover-container">
      <SubEvents childToParentSubEvets={post.title_slug}  key={post.title_slug} />
      </div> : <div></div>
    }


         
        </div>
      </CustomLink>

     
    </>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
