import { useParams, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

import "./Events.css";

import Swim from "./images/racing_conditions_icom_swim.png";

import BikeHilly from "./images/racing_conditions_icom_bike_hilly.png";
import BikeFlat from "./images/racing_conditions_icom_bike_flat.png";
import BikeRolling from "./images/racing_conditions_icom_bike_rolling.png";

import AirTemp from "./images/racing_conditions_icom_weather.png";
import WaterTemp from "./images/racing_conditions_icom_water_temp.png";
import Airport from "./images/racing_conditions_icom_airport.png";

import HOTELS2S from "./images/racing_conditions_icom_hotel_budget.png";
import HOTELS3S from "./images/racing_conditions_icom_hotel_standard.png";
import HOTELS4S from "./images/racing_conditions_icom_hotel_luxury.png";
import DISTANCE from "./images/distance.png";

import SMILE from "./images/smile.svg";

import moment from "moment";

import SignUP from "../../Components/SignUP";
import SingleSponcer from "../../Components/Sponsors";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

import axios from "axios";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
const notyf = new Notyf();

const Events = () => {
  let params = useParams();
  ////console.log(params);

  const [post, setPost] = React.useState();
  const authedUser = sessionStorage.getItem("user");
  const authedID = sessionStorage.getItem("id");
  const authedAuth = sessionStorage.getItem("auth");

  // const [room_type, setroom_type] = useState("");
  const [no_guest, setno_guest] = useState("");
  const [arrival, setarrival] = useState("");
  const [departure, setdeparture] = useState("");
  const [flight_no, setflight_no] = useState("");
  const [other, setother] = useState("");
  const [price, setprice] = useState("");

  const [product_id, setproduct_id] = useState("");
  const [offerID, setofferID] = useState("");
  const [Gallery, setGallery] = useState("");
  const [FIMG, setofferIMG] = useState("");

  let PriceCal = sessionStorage.getItem("PriceCal");

  const handleChange = (e) => {
    setprice(e.target.value);
    console.log(e.target.value);

    var index = e.target.selectedIndex;
    //console.log(e.nativeEvent.target[index].text);
    setno_guest(e.nativeEvent.target[index].text);

    //sessionStorage.setItem("PriceCal", e.target.value);
  };

  const handleSubmitHotel = (event) => {
    event.preventDefault();

    var data = JSON.stringify({
      data: {
        user: authedUser,
        product_id: product_id,
        price: price,
        // room_type: room_type,
        no_guest: no_guest,
        arrival: arrival,
        departure: departure,
        flight_no: flight_no,
        other: other,
        offerID: offerID,
        _by: authedID,
        FIMG: FIMG,
      },
    });

    var config = {
      method: "post",
      url: "https://trime.om/api/api/collections/save/offerscart",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": authedAuth,
      },
      maxRedirects: 0,
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(response.data);
        notyf.success("Add to Cart.");
        //window.location.reload();
        window.location.href = "/cart";
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/sub_events?token=85708875aa7f8dc7d3d2752bfbce31",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            filter: { title_slug: params.id },
          }),
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log("Event Single", response.entries[0]);
          setPost(response.entries[0]);
        });

      // Load Gllery
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = "\r\n";

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://trime.om/api/api/collections/get/gallery?token=85708875aa7f8dc7d3d2752bfbce31&filter[Sub_Event.display]=" +
          params.id,
        requestOptions
      )
        .then((response) => response.json())
        .then(function (response) {
          //console.log("Event Single Check gallery", response.entries);
          setGallery(response.entries);
        });

      //
    };
    fetchData();
  }, [params.id]);

  if (!post) return null;

  return (
    <>
      <picture className="Even">
        <img
          src={`https://trime.om/api/storage/uploads` + post.image.path}
          alt={post.title}
          title={post.title}
          className="EventIMG"
          loading="lazy"
        />
      </picture>

      <div
        style={{
          position: "relative",
          marginTop: "70px",
        }}
      >
        <div className="container grid-xl">
          {/* NEW DESIGN */}

          <div className="container">
            <div className="columns MASTER">
              <div className="column col-auto">
                <div className="DateHolder">
                  <div>{moment(post.date).format("DD")}</div>
                  <div>{moment(post.date).format("MMM")}</div>
                  <div>{moment(post.date).format("YYYY")}</div>
                </div>
              </div>
              <div
                className="column"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="ContHoldingBlock">
                  <div
                    style={{
                      display: "inline-block",
                      margin: "0",
                      paddingRight: "5px",
                      color: "#fc672c",
                      textTransform: "uppercase",
                    }}
                  >
                    About Event:
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      margin: "0",
                    }}
                    dangerouslySetInnerHTML={{ __html: post.content }}
                  />
                </div>

                
              </div>
              
            </div>
          </div>
        </div>

     

        <div
          style={{
            backgroundColor: "#e7e6e5",
            marginTop: "60px",
            marginBottom: "20px",
          }}
        >
          <div className="container grid-xl">
            <div className="colums HoldMe">
              {post.swim ? (
                <div className="HolderICONS">
                  <div className="SigIconHolder">
                    <div>
                      {post.swim === "Ocean" && (
                        <img
                          src={Swim}
                          alt={post.swim}
                          title={post.swim}
                          className="IconSizeIMG"
                          loading="lazy"
                        />
                      )}
                      {post.swim === "Sea" && (
                        <img
                          src={Swim}
                          alt={post.swim}
                          title={post.swim}
                          className="IconSizeIMG"
                          loading="lazy"
                        />
                      )}
                    </div>
                    <div>
                      <h2
                        style={{
                          margin: "0",
                          fontSize: "10px",
                          fontWeight: "600",
                          textTransform: "uppercase",
                        }}
                      >
                        SWIM
                      </h2>
                      <h2
                        style={{
                          margin: "0",
                          fontSize: "18px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          color: "#fc672c",
                        }}
                      >
                        {post.swim}
                      </h2>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {/* Bike */}
              {post.bike ? (
                <div className="HolderICONS">
                  <div className="SigIconHolder">
                    <div>
                      {post.bike === "Hilly" && (
                        <img
                          src={BikeHilly}
                          alt={post.bike}
                          title={post.bike}
                          className="IconSizeIMG"
                          loading="lazy"
                        />
                      )}
                      {post.bike === "Flat" && (
                        <img
                          src={BikeFlat}
                          alt={post.bike}
                          title={post.bike}
                          className="IconSizeIMG"
                          loading="lazy"
                        />
                      )}
                      {post.bike === "Rolling" && (
                        <img
                          src={BikeRolling}
                          alt={post.bike}
                          title={post.bike}
                          className="IconSizeIMG"
                          loading="lazy"
                        />
                      )}
                    </div>
                    <div>
                      <h2
                        style={{
                          margin: "0",
                          fontSize: "10px",
                          fontWeight: "600",
                          textTransform: "uppercase",
                        }}
                      >
                        BIKE
                      </h2>
                      <h2
                        style={{
                          margin: "0",
                          fontSize: "18px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          color: "#fc672c",
                        }}
                      >
                        {post.bike}
                      </h2>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {/* Run */}
              {post.run ? (
                <div className="HolderICONS">
                  <div className="SigIconHolder">
                    <div>
                      {post.run === "Hilly" && (
                        <img
                          src={BikeHilly}
                          alt={post.run}
                          title={post.run}
                          className="IconSizeIMG"
                          loading="lazy"
                        />
                      )}
                      {post.run === "Flat" && (
                        <img
                          src={BikeFlat}
                          alt={post.run}
                          title={post.run}
                          className="IconSizeIMG"
                          loading="lazy"
                        />
                      )}
                      {post.run === "Rolling" && (
                        <img
                          src={BikeRolling}
                          alt={post.run}
                          title={post.run}
                          className="IconSizeIMG"
                          loading="lazy"
                        />
                      )}
                    </div>
                    <div>
                      <h2
                        style={{
                          margin: "0",
                          fontSize: "10px",
                          fontWeight: "600",
                          textTransform: "uppercase",
                        }}
                      >
                        RUN
                      </h2>
                      <h2
                        style={{
                          margin: "0",
                          fontSize: "18px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          color: "#fc672c",
                        }}
                      >
                        {post.run}
                      </h2>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {/* Distance */}
              {post.distance ? (
                <div className="HolderICONS">
                  <div className="SigIconHolder">
                    <div>
                      <img
                        src={DISTANCE}
                        className="IconSizeIMG"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <h2
                        style={{
                          margin: "0",
                          fontSize: "10px",
                          fontWeight: "600",
                          textTransform: "uppercase",
                        }}
                      >
                        DISTANCE
                      </h2>
                      <h2
                        style={{
                          margin: "0",
                          fontSize: "18px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          color: "#fc672c",
                        }}
                      >
                        {/* LOAD Dis HERE */}
                        {post.distance.length &&
                          post.distance.map((Distance, index) => {
                            return (
                              <>
                                <div key={index} className="ListDistance">
                                  {Distance.value.distance}{" "}
                                  {Distance.value.value}
                                  <span>,</span>
                                </div>
                              </>
                            );
                          })}
                      </h2>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              {/* Temp Air */}
              {post.average_air_temp ? (
                <div className="HolderICONS">
                  <div className="SigIconHolder">
                    <div>
                      <img
                        src={AirTemp}
                        alt={post.average_air_temp}
                        title={post.average_air_temp}
                        className="IconSizeIMG"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <h2
                        style={{
                          margin: "0",
                          fontSize: "10px",
                          fontWeight: "600",
                          textTransform: "uppercase",
                        }}
                      >
                        AVG .AIR TEMP
                      </h2>
                      <h2
                        style={{
                          margin: "0",
                          fontSize: "18px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          color: "#fc672c",
                        }}
                      >
                        {post.average_air_temp}
                      </h2>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {/* Water Temp */}
              {post.average_water_temp ? (
                <div className="HolderICONS">
                  <div className="SigIconHolder">
                    <div>
                      <img
                        src={WaterTemp}
                        alt={post.average_water_temp}
                        title={post.average_water_temp}
                        className="IconSizeIMG"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <h2
                        style={{
                          margin: "0",
                          fontSize: "10px",
                          fontWeight: "600",
                          textTransform: "uppercase",
                        }}
                      >
                        AVG .WATER TEMP
                      </h2>
                      <h2
                        style={{
                          margin: "0",
                          fontSize: "18px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          color: "#fc672c",
                        }}
                      >
                        {post.average_water_temp}
                      </h2>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {/* AirPort */}
              {post.air_port ? (
                <div className="HolderICONS">
                  <div className="SigIconHolder">
                    <div>
                      <img
                        src={Airport}
                        alt={post.air_port}
                        title={post.air_port}
                        className="IconSizeIMG"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <h2
                        style={{
                          margin: "0",
                          fontSize: "10px",
                          fontWeight: "600",
                          textTransform: "uppercase",
                        }}
                      >
                        AIRPORT
                      </h2>
                      <h2
                        style={{
                          margin: "0",
                          fontSize: "18px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          color: "#fc672c",
                        }}
                      >
                        {post.air_port}
                      </h2>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Satues */}
      <div
        className="container grid-xl text-right"
        style={{
          fontWeight: "700",
        }}
      >
        <span
          style={{
            color: "#fc672d",
          }}
        >
          STATUS:{" "}
        </span>
        {post.status}
      </div>

      <div
        className="container grid-xl text-right"
        style={{
          fontWeight: "700",
          fontSize: "20px",
        }}
      >
        <span
          style={{
            color: "#fc672d",
          }}
        >
          PRICE:{" "}
        </span>
        {post.price} OMR
      </div>


      <div className="container grid-xl" style={{
          marginTop: "60px"
        }}>
        <div className="columns">
                {post.Rep_Content &&
                  post.Rep_Content.map((data, index) => {
                    return (
                      <div
                        className="column col-2 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
                        key={index}
                      >
                       
                       <div
                    style={{
                    }}
                    dangerouslySetInnerHTML={{ __html: data.value.details }}
                  />

                       
                      </div>
                    );
                  })}
              </div>

        </div>

      <div
        style={{
          backgroundColor: "white",
          marginTop: "60px",
          marginBottom: "20px",
        }}
      >
        <div className="container grid-xl">
          <div className="colums HoldMe">
            <h1
              style={{
                fontSize: "24px",
                marginBottom: "25px",
                color: "#fd682d",
                fontWeight: "900",
              }}
            >
              OFFICIAL SPONSORS
            </h1>
            <Swiper
              className="mySwiperAssociatesSlider"
              slidesPerView={1}
              spaceBetween={30}
              slidesPerGroup={1}
              loop={false}
              loopFillGroupWithBlank={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              breakpoints={{
                400: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                  slidesPerGroup: 1,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                  slidesPerGroup: 2,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                  slidesPerGroup: 4,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                  slidesPerGroup: 5,
                },
              }}
            >
              {post.sponsors &&
                post.sponsors.map((slider, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <SingleSponcer sponsors_ID={slider._id} />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
      </div>



     
           <div className="container grid-xl">
           <h1
             style={{
               fontSize: "24px",
               marginBottom: "0px",
               color: "#fd682d",
               fontWeight: "900",
             }}
           >
             HOTEL & TOURS OFFERS
           </h1>
           <h5
             style={{
               marginBottom: "30px",
             }}
           >
             Take advantage of our accomodation, and site seeing offers during stay
             for the events.
           </h5>
         </div>
       



    

      <div
        style={{
          backgroundColor: "#e7e6e4",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >




        {/* HOTELS */}
        {post.offers_hotels  ? (
          <div className="container grid-xl Extra">
            <div className="LeftHoldDetails">
              <h1
                style={{
                  fontSize: "24px",
                  marginBottom: "10px",
                  color: "#fd682d",
                  fontWeight: "900",
                }}
              >
                HOTELS
              </h1>
            </div>

            {/* HOTES */}
            <div className="RightHoldDetails">
              <div className="columns">
                {post.offers_hotels &&
                  post.offers_hotels.map((data, index) => {
                    return (
                      <div
                        className="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                        key={index}
                      >
                        <div className="ImgHolder">
                          {data.value.select === "2" ? (
                            <img
                              src={HOTELS2S}
                              className="IconSizeIMG ICO"
                              loading="lazy"
                            />
                          ) : (
                            <div></div>
                          )}
                          {data.value.select === "3" ? (
                            <img
                              src={HOTELS3S}
                              className="IconSizeIMG ICO"
                              loading="lazy"
                            />
                          ) : (
                            <div></div>
                          )}
                          {data.value.select === "4" ? (
                            <img
                              src={HOTELS4S}
                              className="IconSizeIMG ICO"
                              loading="lazy"
                            />
                          ) : (
                            <div></div>
                          )}

                          <img
                            src={
                              `https://trime.om/api/storage/uploads` +
                              data.value.image.path
                            }
                            className="OfferIMG"
                            loading="lazy"
                          />
                          
                        </div>
                        <h6 style={{
                          display: "inline-block",
                          fontWeight: "700"
                        }}>{data.value.id}</h6>
                        {/* <h5 style={{
                          display: "inline-block",
                          fontWeight: "bold",
                          margin: "0",
                          backgroundColor: "#fd682d",
                          color: "white",
                          paddingTop: "0",
                          paddingRight: "5px",
                          paddingBottom: "0",
                          paddingLeft: "5px",
                          marginLeft: "5px",
                          borderRadius: "4px"
                        }}>{data.value.price} OMR</h5> */}

                        {authedUser ? (
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "10px",
                              marginBottom: "10px",
                              display: "flex",
                              flexDirection: "column",
                              flexWrap: "wrap",
                              alignItems: "baseline",
                              alignContent: "center",
                            }}
                          >
                            <a
                              className="btnSignUpPOP2"
                              href={"#booknowHotel_" + index}
                              style={{
                                marginLeft: "0px",
                              }}
                            >
                              BOOK NOW
                            </a>

                            <a
                              className="btnSignUpPOP2"
                              href={data.value.view}
                              style={{
                                marginLeft: "0px",
                                marginTop: "10px",
                              }}
                              target="_blank"
                            >
                              VIEW HOTEL
                            </a>

                            <div className="modal" id={"booknowHotel_" + index}>
                              <a
                                className="modal-overlay"
                                href="#close"
                                aria-label="Close"
                              >
                                &#8203;
                              </a>
                              <div className="modal-container" role="document">
                                <div className="modal-header">
                                  <a
                                    style={{
                                      top: "5px",
                                      position: "relative",
                                    }}
                                    className="btn btn-clear float-right"
                                    href="#close"
                                    aria-label="Close"
                                  >
                                    &#8203;
                                  </a>
                                  <div className="modal-title h5">
                                    {/* {post.title} <b>( {post.offers_hotels[0].value.price} OMR )</b> */}
                                    {post.title} <b>( {price} OMR )</b>
                                  </div>
                                </div>
                                <div className="modal-body">
                                  <div className="content">
                                    <form
                                      id="Hotel"
                                      className="Login"
                                      onSubmit={handleSubmitHotel}
                                    >
                                      {/* <div className="form-group">
                                        <label
                                          class="form-label"
                                          for="input-example-1"
                                        >
                                          Room Type
                                        </label>

                                        <select
                                          id="room_type"
                                          className="form-select"
                                          onChange={(event) =>
                                            setroom_type(event.target.value)
                                          }
                                        >
                                          <option>Choose an option</option>
                                          <option value="Budget">Budget</option>
                                          <option value="Standard">
                                            Standard
                                          </option>
                                          <option value="Luxury">Luxury</option>
                                        </select>
                                      </div> */}

                                      <div class="form-group">
                                        <label
                                          class="form-label"
                                          for="input-example-1"
                                        >
                                          No of Guest
                                        </label>

                                        <select
                                          id="room_type"
                                          className="form-select"
                                          onChange={handleChange}
                                          //value={value}
                                        >
                                          <option>Choose an option</option>
                                          <option
                                            value={
                                              post.offers_hotels[0].value.price
                                            }
                                          >
                                            1
                                          </option>
                                          <option
                                            value={
                                              post.offers_hotels[0].value.price
                                            }
                                          >
                                            2
                                          </option>
                                          <option
                                            value={
                                              post.offers_hotels[0].value
                                                .price * 2
                                            }
                                          >
                                            3
                                          </option>
                                          <option
                                            value={
                                              post.offers_hotels[0].value
                                                .price * 2
                                            }
                                          >
                                            4
                                          </option>
                                          <option
                                            value={
                                              post.offers_hotels[0].value
                                                .price * 3
                                            }
                                          >
                                            5
                                          </option>
                                          <option
                                            value={
                                              post.offers_hotels[0].value
                                                .price * 3
                                            }
                                          >
                                            6
                                          </option>
                                          <option
                                            value={
                                              post.offers_hotels[0].value
                                                .price * 4
                                            }
                                          >
                                            7
                                          </option>
                                          <option
                                            value={
                                              post.offers_hotels[0].value
                                                .price * 4
                                            }
                                          >
                                            8
                                          </option>
                                        </select>
                                      </div>

                                      <div className="DateHolderHalf">
                                        <div class="form-group Half">
                                          <label
                                            class="form-label"
                                            for="input-example-1"
                                          >
                                            Arrival
                                          </label>

                                          <input
                                            type="datetime-local"
                                            id="arrival"
                                            name="arrival"
                                            value={arrival}
                                            onChange={(event) =>
                                              setarrival(event.target.value)
                                            }
                                            required
                                            placeholder="Arrival"
                                            class="form-input"
                                          />
                                        </div>

                                        <div class="form-group Half">
                                          <label
                                            class="form-label"
                                            for="input-example-1"
                                          >
                                            Departure
                                          </label>

                                          <input
                                            type="datetime-local"
                                            id="departure"
                                            name="departure"
                                            value={departure}
                                            onChange={(event) =>
                                              setdeparture(event.target.value)
                                            }
                                            required
                                            placeholder="Departure"
                                            class="form-input"
                                          />
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label
                                          class="form-label"
                                          for="input-example-1"
                                        >
                                          Flight No
                                        </label>
                                        <input
                                          type="text"
                                          id="flight_no"
                                          name="flight_no"
                                          value={flight_no}
                                          onChange={(event) =>
                                            setflight_no(event.target.value)
                                          }
                                          required
                                          placeholder="Flight No"
                                          class="form-input"
                                        />
                                      </div>


                                      

                                      <div class="form-group">
                                        <label
                                          class="form-label"
                                          for="input-example-1"
                                        >
                                          Other Request
                                        </label>

                                        <textarea
                                          class="form-input"
                                          id="input-example-3"
                                          value={other}
                                          onChange={(event) =>
                                            setother(event.target.value)
                                          }
                                          required
                                          placeholder="Other Request"
                                          rows="3"
                                        ></textarea>
                                      </div>

                                      <button
                                        className="btnSignUpPOP2"
                                        type="submit"
                                        onClick={() => (
                                          // setprice(post.price),
                                          setproduct_id(post._id),
                                          setofferID(data.value.id),
                                          setofferIMG(data.value.image.path)
                                        )}
                                      >
                                        ADD TO CART
                                      </button>
                                    </form>
                                  </div>
                                </div>
                                <div className="modal-footer"></div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <Link
                              to="/register"
                              className="btnSignUpPOP2"
                              style={{
                                marginLeft: "0px",
                              }}
                            >
                              REGISTER
                            </Link>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>

              {post.offers_hotels.length == "0"  ? (
        <div><h1 style={{
          fontSize: "15px",
          marginBottom: "10px",
          color: "#fd682d",
          fontWeight: "900",
          marginTop: "5px"
        }}>NOT AVAILABLE</h1></div>
        ) : (
        <div></div>
        )}


            </div>
          </div>
        ) : (
          <div></div>
        )}


        {/* LAND TOURS */}
        {post.offers_LandTours  ? (
          <div className="container grid-xl Extra">
            <div className="LeftHoldDetails">
              <h1
                style={{
                  fontSize: "24px",
                  marginBottom: "10px",
                  color: "#fd682d",
                  fontWeight: "900",
                }}
              >
                LAND
                <br />
                TOURS
              </h1>
            </div>
            <div className="RightHoldDetails">
              <div className="columns">
                {post.offers_LandTours &&
                  post.offers_LandTours.map((data, index) => {
                    return (
                      <div
                        className="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                        key={index}
                      >
                        <div className="ImgHolder">
                          <div className="HoldBox">
                          <h6 style={{
                          display: "inline-block",
                          fontWeight: "700"
                        }}>{data.value.id}</h6>
                        {/* <h5 style={{
                          display: "inline-block",
                          fontWeight: "bold",
                          margin: "0",
                          backgroundColor: "#fd682d",
                          color: "white",
                          paddingTop: "0",
                          paddingRight: "5px",
                          paddingBottom: "0",
                          paddingLeft: "5px",
                          marginLeft: "5px",
                          borderRadius: "4px"
                        }}>{data.value.price} OMR</h5> */}
                          </div>
                          <img
                            src={
                              `https://trime.om/api/storage/uploads` +
                              data.value.image.path
                            }
                            className="OfferIMG"
                            loading="lazy"
                          />
                           
                        </div>
                        {authedUser ? (
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "10px",
                              marginBottom: "10px",
                              display: "flex",
                              flexDirection: "column",
                              flexWrap: "wrap",
                              alignItems: "baseline",
                              alignContent: "center",
                            }}
                          >
                            <a
                              className="btnSignUpPOP2"
                              href={"#booknowLandTours_" + index}
                              style={{
                                marginLeft: "0px",
                              }}
                            >
                              BOOK NOW
                            </a>

                            <div
                              className="modal"
                              id={"booknowLandTours_" + index}
                            >
                              <a
                                className="modal-overlay"
                                href="#close"
                                aria-label="Close"
                              >
                                &#8203;
                              </a>
                              <div className="modal-container" role="document">
                                <div className="modal-header">
                                  <a
                                    style={{
                                      top: "5px",
                                      position: "relative",
                                    }}
                                    className="btn btn-clear float-right"
                                    href="#close"
                                    aria-label="Close"
                                  >
                                    &#8203;
                                  </a>
                                  <div className="modal-title h5">
                                    {post.title}{" "}
                                    <b>
                                      ({" "}
                                      {data.value.price *
                                        no_guest}{" "}
                                      OMR )
                                    </b>
                                  </div>

                                  

                                  
                                </div>
                                <div className="modal-body">
                                  <div className="content">
                                  

                                  

                                    <form
                                      id="Hotel"
                                      className="Login"
                                      onSubmit={handleSubmitHotel}
                                    >

                                      
                                      <div class="form-group">
                                        <label
                                          class="form-label"
                                          for="input-example-1"
                                        >
                                          No of Cars <small>(*up to 3 persons per car)</small>
                                        </label>

                                        <input
                                          type="number"
                                          id="no_guest"
                                          name="no_guest"
                                          value={no_guest}
                                          onChange={(event) =>
                                            setno_guest(event.target.value)
                                          }
                                          required
                                          placeholder="No of Cars"
                                          class="form-input"
                                        />
                                      </div>

                                      <div className="DateHolderHalf">
                                        <div class="form-group Half">
                                          <label
                                            class="form-label"
                                            for="input-example-1"
                                          >
                                            Arrival
                                          </label>

                                          <input
                                            type="datetime-local"
                                            id="arrival"
                                            name="arrival"
                                            value={arrival}
                                            onChange={(event) =>
                                              setarrival(event.target.value)
                                            }
                                            required
                                            placeholder="Arrival"
                                            class="form-input"
                                          />
                                        </div>

                                        <div class="form-group Half">
                                          <label
                                            class="form-label"
                                            for="input-example-1"
                                          >
                                            Departure
                                          </label>




                                          <input
                                            type="datetime-local"
                                            id="departure"
                                            name="departure"
                                            value={departure}
                                            onChange={(event) =>
                                              setdeparture(event.target.value)
                                            }
                                            required
                                            placeholder="Departure"
                                            class="form-input"
                                          />
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label
                                          class="form-label"
                                          for="input-example-1"
                                        >
                                          Flight No
                                        </label>
                                        <input
                                          type="text"
                                          id="flight_no"
                                          name="flight_no"
                                          value={flight_no}
                                          onChange={(event) =>
                                            setflight_no(event.target.value)
                                          }
                                          required
                                          placeholder="Flight No"
                                          class="form-input"
                                        />
                                      </div>



                                      <div
                  className="DesignBody"
                    dangerouslySetInnerHTML={{ __html: data.value.details }}
                  />



                                      <div class="form-group">
                                        <label
                                          class="form-label"
                                          for="input-example-1"
                                        >
                                          Other Request
                                        </label>

                                        <textarea
                                          class="form-input"
                                          id="input-example-3"
                                          value={other}
                                          onChange={(event) =>
                                            setother(event.target.value)
                                          }
                                          required
                                          placeholder="Other Request"
                                          rows="3"
                                        ></textarea>
                                      </div>

                                      <button
                                        className="btnSignUpPOP2"
                                        type="submit"
                                        onClick={() => (
                                          setprice(
                                            post.offers_LandTours[0].value
                                              .price * no_guest
                                          ),
                                          setproduct_id(post._id),
                                          setofferID(data.value.id),
                                          setofferIMG(data.value.image.path)
                                        )}
                                      >
                                        ADD TO CART
                                      </button>
                                    </form>
                                  </div>
                                </div>
                                <div className="modal-footer"></div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <Link
                              to="/register"
                              className="btnSignUpPOP2"
                              style={{
                                marginLeft: "0px",
                              }}
                            >
                              REGISTER
                            </Link>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>

              {post.offers_LandTours.length == "0"  ? (
        <div><h1 style={{
          fontSize: "15px",
          marginBottom: "10px",
          color: "#fd682d",
          fontWeight: "900",
          marginTop: "5px"
        }}>NOT AVAILABLE</h1></div>
        ) : (
        <div></div>
        )}

            </div>
          </div>
        ) : (
          <div></div>
        )}



        {/* SEA TOURS */}
        {post.offers_SeaTours ? (
          <div className="container grid-xl Extra">
            <div className="LeftHoldDetails">
              <h1
                style={{
                  fontSize: "24px",
                  marginBottom: "10px",
                  color: "#fd682d",
                  fontWeight: "900",
                }}
              >
                SEA
                <br />
                TOURS
              </h1>
            </div>
            <div className="RightHoldDetails">
              <div className="columns">
                {post.offers_SeaTours &&
                  post.offers_SeaTours.map((data, index) => {
                    return (
                      <div
                        className="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                        key={index}
                      >
                        <div className="ImgHolder">
                        <div className="HoldBox">
                          <h6 style={{
                          display: "inline-block",
                          fontWeight: "700"
                        }}>{data.value.id}</h6>
                        {/* <h5 style={{
                          display: "inline-block",
                          fontWeight: "bold",
                          margin: "0",
                          backgroundColor: "#fd682d",
                          color: "white",
                          paddingTop: "0",
                          paddingRight: "5px",
                          paddingBottom: "0",
                          paddingLeft: "5px",
                          marginLeft: "5px",
                          borderRadius: "4px"
                        }}>{data.value.price} OMR</h5> */}
                          </div>
                          <img
                            src={
                              `https://trime.om/api/storage/uploads` +
                              data.value.image.path
                            }
                            className="OfferIMG"
                            loading="lazy"
                          />
                        </div>
                        {authedUser ? (
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "10px",
                              marginBottom: "10px",
                              display: "flex",
                              flexDirection: "column",
                              flexWrap: "wrap",
                              alignItems: "baseline",
                              alignContent: "center",
                            }}
                          >
                            <a
                              className="btnSignUpPOP2"
                              href={"#booknowSeaTours_" + index}
                              style={{
                                marginLeft: "0px",
                              }}
                            >
                              BOOK NOW
                            </a>

                            <div
                              className="modal"
                              id={"booknowSeaTours_" + index}
                            >
                              <a
                                className="modal-overlay"
                                href="#close"
                                aria-label="Close"
                              >
                                &#8203;
                              </a>
                              <div className="modal-container" role="document">
                                <div className="modal-header">
                                  <a
                                    style={{
                                      top: "5px",
                                      position: "relative",
                                    }}
                                    className="btn btn-clear float-right"
                                    href="#close"
                                    aria-label="Close"
                                  >
                                    &#8203;
                                  </a>
                                  <div className="modal-title h5">
                                    {post.title}{" "}
                                    <b>
                                      ({" "}
                                      {data.value.price *
                                        no_guest}{" "}
                                      OMR )
                                    </b>
                                  </div>
                                </div>
                                <div className="modal-body">
                                  <div className="content">
                                    <form
                                      id="Hotel"
                                      className="Login"
                                      onSubmit={handleSubmitHotel}
                                    >
                                      <div class="form-group">
                                        <label
                                          class="form-label"
                                          for="input-example-1"
                                        >
                                          No of Guest
                                        </label>

                                        <input
                                          type="number"
                                          id="no_guest"
                                          name="no_guest"
                                          value={no_guest}
                                          onChange={(event) =>
                                            setno_guest(event.target.value)
                                          }
                                          required
                                          placeholder="No of Guest"
                                          class="form-input"
                                        />
                                      </div>

                                      <div className="DateHolderHalf">
                                        <div class="form-group Half">
                                          <label
                                            class="form-label"
                                            for="input-example-1"
                                          >
                                            Arrival
                                          </label>

                                          <input
                                            type="datetime-local"
                                            id="arrival"
                                            name="arrival"
                                            value={arrival}
                                            onChange={(event) =>
                                              setarrival(event.target.value)
                                            }
                                            required
                                            placeholder="Arrival"
                                            class="form-input"
                                          />
                                        </div>

                                        <div class="form-group Half">
                                          <label
                                            class="form-label"
                                            for="input-example-1"
                                          >
                                            Departure
                                          </label>

                                          <input
                                            type="datetime-local"
                                            id="departure"
                                            name="departure"
                                            value={departure}
                                            onChange={(event) =>
                                              setdeparture(event.target.value)
                                            }
                                            required
                                            placeholder="Departure"
                                            class="form-input"
                                          />
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label
                                          class="form-label"
                                          for="input-example-1"
                                        >
                                          Flight No
                                        </label>
                                        <input
                                          type="text"
                                          id="flight_no"
                                          name="flight_no"
                                          value={flight_no}
                                          onChange={(event) =>
                                            setflight_no(event.target.value)
                                          }
                                          required
                                          placeholder="Flight No"
                                          class="form-input"
                                        />
                                      </div>

                                      <div
                  className="DesignBody"
                    dangerouslySetInnerHTML={{ __html: data.value.details }}
                  />

                                      <div class="form-group">
                                        <label
                                          class="form-label"
                                          for="input-example-1"
                                        >
                                          Other Request
                                        </label>

                                        <textarea
                                          class="form-input"
                                          id="input-example-3"
                                          value={other}
                                          onChange={(event) =>
                                            setother(event.target.value)
                                          }
                                          required
                                          placeholder="Other Request"
                                          rows="3"
                                        ></textarea>
                                      </div>

                                      <button
                                        className="btnSignUpPOP2"
                                        type="submit"
                                        onClick={() => (
                                          setprice(
                                            post.offers_SeaTours[0].value
                                              .price * no_guest
                                          ),
                                          setproduct_id(post._id),
                                          setofferID(data.value.id),
                                          setofferIMG(data.value.image.path)
                                        )}
                                      >
                                        ADD TO CART
                                      </button>
                                    </form>
                                  </div>
                                </div>
                                <div className="modal-footer"></div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <Link
                              to="/register"
                              className="btnSignUpPOP2"
                              style={{
                                marginLeft: "0px",
                              }}
                            >
                              REGISTER
                            </Link>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>

              {post.offers_SeaTours.length == "0"  ? (
        <div><h1 style={{
          fontSize: "15px",
          marginBottom: "10px",
          color: "#fd682d",
          fontWeight: "900",
          marginTop: "5px"
        }}>NOT AVAILABLE</h1></div>
        ) : (
        <div></div>
        )}


            </div>
          </div>
        ) : (
          <div></div>
        )}
        {/*  */}


      </div>

      <div
        className="container grid-xl text-right MOB"
        style={{
          paddingTop: "50px",
        }}
      >
        {Gallery.length ? (
          // <Link
          //   className="Link GALLERY"
          //   to={`/gallery/` + params.slug + `/` + post.title_slug}
          // >
          //   GALLERY
          // </Link>
          <Link className="Link GALLERY" to={`/gallery/` + params.slug + `/`}>
            GALLERY
          </Link>
        ) : (
          <div></div>
        )}

        <div
          className="SignUPBtn"
          style={{
            display: "inline",
          }}
        >
          <Link className="btnSignUpPOP" to={`/contact-us`}>
            CONTACT US
          </Link>

          {/* <a className="btnSignUpPOP" href="#ENQUIRIES">
            ENQUIRIES
          </a> */}
        </div>
        {/* SignUP */}
        <div
          className="SignUPBtn"
          style={{
            display: "inline",
          }}
        >
          {post.signup ? (
            // IF LOAD THIS
            <>
              {/* <a className="btnSignUpPOP2" href={post.signup} target="_blank">
                SIGN UP
              </a> */}
              <a className="btnSignUpPOP2" href="#SignUP_Redirect">
                REGISTER
              </a>
            </>
          ) : (
            //
            <a className="btnSignUpPOP2" href="#SignUP">
              REGISTER
            </a>
          )}

          <div className="modal" id="SignUP">
            <a className="modal-overlay" href="#close" aria-label="Close">
              &#8203;
            </a>
            <div className="modal-container" role="document">
              <div className="modal-header">
                <a
                  style={{
                    top: "5px",
                    position: "relative",
                  }}
                  className="btn btn-clear float-right"
                  href="#close"
                  aria-label="Close"
                >
                  &#8203;
                </a>
                <div className="modal-title h5">{post.title} <b>{post.price} OMR</b>  

                {post.Price_notes ? (
            // IF LOAD THIS
            <>
            <small style={{
                  display: "block",
                  fontSize: "14px"
                }}>({post.Price_notes})</small>
            </>
          ) : (
            //
            <span></span>
          )}


                
                
                </div>
              </div>
              <div className="modal-body">
                <div className="content">
                  <SignUP
                    eventName={post.title}
                    id={post._id}
                    price={post.price}
                  />
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>

          {/* LatePOPUP */}
          <div className="modal" id="SignUP_Redirect">
            <a className="modal-overlay" href="#close" aria-label="Close">
              &#8203;
            </a>
            <div className="modal-container DARK" role="document">
              <div className="modal-header">
                <a
                  style={{
                    top: "5px",
                    position: "relative",
                    color: "white",
                  }}
                  className="btn btn-clear float-right"
                  href="#close"
                  aria-label="Close"
                >
                  &#8203;
                </a>
                <div
                  className="modal-title h5"
                  style={{
                    color: "#fd682d",
                  }}
                >
                  {post.title}
                </div>
              </div>
              <div className="modal-body">
                <div className="content">
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    WISHING YOU THE BEST OF LUCK
                    <div
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <img
                        src={SMILE}
                        className="IconSizeIMGSMILE"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      After you have signed up
                      <br />
                      feel free to take advantage of our offers
                    </div>
                    <div className="OFFHolder">
                      <div className="H1Holder">
                        HOTEL
                        <br />
                        OFFERS
                      </div>
                      <div className="H2Holder">
                        SEA TOUR
                        <br />
                        OFFERS
                      </div>
                      <div className="H3Holder">
                        LAND TOUR
                        <br />
                        OFFERS
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "40px",
                        display: "inline-block",
                      }}
                    >
                      <a
                        style={{
                          top: "5px",
                          position: "relative",
                          color: "#343434",
                          backgroundColor: "white",
                          borderRadius: "10px",
                          paddingTop: "7px",
                          paddingRight: "15px",
                          paddingBottom: "7px",
                          paddingLeft: "15px",
                          marginRight: "5px",
                        }}
                        href="#close"
                        aria-label="Close"
                      >
                        SEE OFFERS
                      </a>
                      <a
                        style={{
                          top: "5px",
                          position: "relative",
                          color: "rgb(255 255 255)",
                          backgroundColor: "#fb692e",
                          borderRadius: "10px",
                          paddingTop: "7px",
                          paddingRight: "15px",
                          paddingBottom: "7px",
                          paddingLeft: "15px",
                          marginLeft: "5px",
                        }}
                        href={post.signup}
                        target="_blank"
                      >
                        CONTINUE TO SIGN UP
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
          {/*  */}
        </div>
      </div>

      <div
        style={{
          position: "relative",
          marginBottom: "-6px",
        }}
      >
        <div className="FooterEffect"></div>
        <img
          src={`https://trime.om/api/storage/uploads` + post.footer_image.path}
          alt={post.title}
          title={post.title}
          className="EventIMGFooter"
          loading="lazy"
        />
      </div>
    </>
  );
};
export default Events;
