import { useParams, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Gallery.css";
import GallerySingleLoad from "../../Components/GallerySingle";

const GallerySingle = () => {
  let params = useParams();
  ////console.log(params);

  const [post, setPost] = React.useState();
  const [events, setPostSUBGallery] = React.useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/events?token=85708875aa7f8dc7d3d2752bfbce31",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            filter: { title_slug: params.slug },
          }),
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log("Gallery Event Data", response.entries[0]);
          setPost(response.entries[0]);
        });
      // List Sub Gallerys
      const responseSUBGallery = await fetch(
        "https://trime.om/api/api/collections/get/gallery?token=85708875aa7f8dc7d3d2752bfbce31&filter[Main_Event.display]=" +
          params.slug,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
        }
      );
      await responseSUBGallery
        .json()

        .then(function (responseSUBGallery) {
          console.log("Event Single", responseSUBGallery.entries);
          setPostSUBGallery(responseSUBGallery.entries);
        });
      //
    };
    fetchData();
  }, [params.slug]);

  if (!post) return null;

  return (
    <>
      <div className="GalleryHolder">
        <img
          src={`https://trime.om/api/storage/uploads` + post.event_logo.path}
          alt={post.title}
          title={post.title}
          className="GalleryIMGSingleEventLogo"
          loading="lazy"
        />
        <h3>Browse your photos & download</h3>
      </div>

      <div className="container grid-xl Event">
        <div className="columns">
          {events &&
            events.map((event, index) => {
              return (
                <div
                  className="column col-4 col-xs-12 col-sm-12 col-md-2 col-lg-4 col-xl-4 EFFECT"
                  key={index}
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  <GallerySingleLoad
                    LoadGallery={event.Sub_Event.display}
                    imagePath={event.gallery[0].value.view.path}
                    GaleryDate={event.date}
                    GalleryTitle={event.gallery_title_slug}
                  />
                                    <h5 className="EventDate">{event.gallery_title}</h5>

                  <h5 className="EventDate">{event.date}</h5>
                  

                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};
export default GallerySingle;
