import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function LoadGallery({ LoadGallery, imagePath, GalleryTitle }) {
  const [event, setPost] = React.useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/sub_events?token=85708875aa7f8dc7d3d2752bfbce31",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            filter: { title_slug: LoadGallery },
          }),
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log(LoadGallery, response.entries[0]);
          setPost(response.entries[0]);


          
        });

       


    };
    fetchData();
  }, [LoadGallery]);

  if (!event) return null;

  return (
    <>
      <Link className="Link" to={event.title_slug + `/` + GalleryTitle}>
        <img
          src={`https://trime.om/api/storage/uploads/` + imagePath}
          alt={event.title}
          title={event.title}
          className="EventLogoIMGSingleV2"
          loading="lazy"
        />
      </Link>

      
     
    </>
  );
}
