import React from "react";
import axios from "axios";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function Child({ childToParentSub }) {
  const baseURL =
    "https://trime.om/api/api/collections/entry/pages/" +
    childToParentSub +
    "?token=85708875aa7f8dc7d3d2752bfbce31";

  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    axios.get(baseURL).then((response) => {
      setPost(response.data);
    });
  }, []);

  if (!post) return null;

  return (
    <>
    <CustomLink title={post.title} to={post.title_slug}>
      {post.title} 
    </CustomLink>
    </>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
