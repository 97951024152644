import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./ContactUs.css";
import axios from "axios";

import { ReactComponent as Whatsapp } from "./images/whatsapp-svgrepo-com.svg";

const Page = () => {
  let params = useParams();
  ////console.log(params);

  const [post, setPost] = React.useState();
  const [info, setSettings] = React.useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/pages?token=85708875aa7f8dc7d3d2752bfbce31",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            filter: { _id: "ce963ed03734669ba70002ef" },
          }),
        }
      );
      await response
        .json()

        .then(function (response) {
          ////console.log("Page Data", response.entries[0]);
          setPost(response.entries[0]);
        });
    };
    fetchData();

    // Setting
    var data = "";

    var config = {
      method: "post",
      url: "https://trime.om/api/api/singletons/get/settings?token=85708875aa7f8dc7d3d2752bfbce31",
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        ////console.log("SETTINGS",response.data);
        setSettings(response.data);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [params.slug]);

  if (!post) return null;
  if (!info) return null;

  return (
    <>
      <picture
        className="ContactUs"
        style={{
          position: "relative",
        }}
      >
        <div className="ContactBottom">
          {info.Email ? (
            <a
              className="ContButton"
              target="_blank"
              href={`mailto:` + info.Email}
            >
              {info.Email}
            </a>
          ) : (
            <div></div>
          )}
          {info.Email_support ? (
            <a
              className="ContButton"
              target="_blank"
              href={`mailto:` + info.Email_support}
            >
              {info.Email_support}
            </a>
          ) : (
            <div></div>
          )}
          {info.WhatsApp ? (
            <a target="_blank" href={`https://wa.me/` + info.WhatsApp}>
              <Whatsapp />
            </a>
          ) : (
            <div></div>
          )}
        </div>
        <source
          media="(min-width: 1281px)"
          srcSet={`https://trime.om/api/storage/uploads` + post.image.path}
        />
        <source
          media="(min-width: 601px)"
          srcSet={`https://trime.om/api/storage/uploads` + post.image_tab.path}
        />
        <source
          media="(min-width: 600px)"
          srcSet={
            `https://trime.om/api/storage/uploads` + post.image_mobile.path
          }
        />
        <img
          src={`https://trime.om/api/storage/uploads` + post.image_mobile.path}
          alt={post.title}
          title={post.title}
          className="ContactUsIMG"
          loading="lazy"
        />
      </picture>

      <div
        style={{
          position: "relative",
        }}
      ></div>
    </>
  );
};
export default Page;
