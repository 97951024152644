import { useParams, Link } from "react-router-dom";
import React, { useEffect } from "react";
import "./Event.css";

const Event = () => {
  let params = useParams();
  ////console.log(params);

  const [post, setPost] = React.useState();
  const [events, setPostEvents] = React.useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/entry/pages/ce391485613939655d000212?token=85708875aa7f8dc7d3d2752bfbce31",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
        }
      );
      await response
        .json()

        .then(function (response) {
          ////console.log("Event Page Data", response);
          setPost(response);
        });

      // EVENTS LOAD
      const responseEvents = await fetch(
        "https://trime.om/api/api/collections/get/events?token=85708875aa7f8dc7d3d2752bfbce31",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
        }
      );
      await responseEvents
        .json()

        .then(function (responseEvents) {
          //console.log("Event Data", responseEvents.entries);
          setPostEvents(responseEvents.entries);
        });
    };
    fetchData();
  }, [params.slug]);

  if (!post) return null;

  return (
    <>
     

      <picture className="Page">
        <source
          media="(min-width: 1281px)"
          srcSet={`https://trime.om/api/storage/uploads` + post.image.path}
        />
        <source
          media="(min-width: 601px)"
          srcSet={`https://trime.om/api/storage/uploads` + post.image_tab.path}
        />
        <source
          media="(min-width: 600px)"
          srcSet={
            `https://trime.om/api/storage/uploads` + post.image_mobile.path
          }
        />
        <img
          src={`https://trime.om/api/storage/uploads` + post.image_mobile.path}
          alt={post.title}
          title={post.title}
          className="PageIMG"
          loading="lazy"
        />
      </picture>

      <div className="container grid-xl Event">
        <div className="columns">
          {events &&
            events.map((event, index) => {
              return (
                <div
                  className="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  key={index}
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  <Link className="Link" to={event.title_slug}>
                    <img
                      src={
                        `https://trime.om/api/storage/uploads` +
                        event.event_logo.path
                      }
                      alt={event.title}
                      title={event.title}
                      className="EventLogoIMGSingle"
                      loading="lazy"
                    />
                  </Link>
                  <h4 className="EventTitle">{event.title}</h4>
                  {/* <h5 className="EventDate">{event.date}</h5> */}
                  {/* Test */}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};
export default Event;
