import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./HomeAboutUs.css";

const HomeAboutUs = (props) => {
  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    var data = "";

    var config = {
      method: "get",
      url: "https://trime.om/api/api/collections/entry/pages/ce8ff2073163305b71000235?token=85708875aa7f8dc7d3d2752bfbce31",
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        ////console.log("HomeAboutUs", response.data);
        setPost(response.data);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  if (!post) return null;

  return (
    <>
      <div className="HomeAboutUs">
        <div
          className="container"
          style={{
            position: "relative",
            paddingLeft: "0",
            paddingRight: "0",
            top: "0px",
          }}
        >
          <div className="GradintHomeAboutUs"></div>
          <div className="columns">
            <div
              className="column col-6 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
              style={{
                zIndex: "1",
                color: "black",
                textAlign: "left",
                padding: "30px",
                display: "flex",
                flexFlow: "column wrap",
                justifyContent: "flex-start",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <div className="MaxWidthDiv">{post.excerpt}</div>

              <div
                className="text-right"
                style={{
                  width: "100%",
                  marginTop: "25px",
                }}
              >
                <Link
                  className="Link"
                  to={post.title_slug}
                  style={{
                    color: "#fb5116",
                  }}
                >
                  Read More
                </Link>
              </div>
            </div>

            <div
              className="column col-6 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
              style={{
                backgroundImage: `url(${
                  `https://trime.om/api/storage/uploads` + post.pager_image.path
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center right",
                padding: "30px",
                minHeight: "365px",
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeAboutUs;
