import { useParams, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Events.css";

const Events = () => {
  let params = useParams();
  ////console.log(params);

  const [post, setPost] = React.useState();
  const [events, setPostSUB] = React.useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/events?token=85708875aa7f8dc7d3d2752bfbce31",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            filter: { title_slug: params.slug },
          }),
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log("Event Single", response.entries[0]);
          setPost(response.entries[0]);
          setIsLoading(false);
        });

      // SUB EVENTS
      const responseSUB = await fetch(
        "https://trime.om/api/api/collections/get/sub_events?token=85708875aa7f8dc7d3d2752bfbce31&filter[event.display]=" +
          params.slug + "&filter[published]=true",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
        }
      );
      await responseSUB
        .json()

        .then(function (responseSUB) {
          //console.log("Event Single SUB LIST", responseSUB.entries);
          setPostSUB(responseSUB.entries);
        });
      //
    };
    fetchData();
  }, [params.slug]);

  if (!post) return null;

  return (
    <>
      {isLoading ? (
        <div
          className="loading loading-lg"
          style={{
            position: "fixed",
            zIndex: "10",
            left: "0",
            right: "0",
            top: "0",
            bottom: "0",
            backgroundColor: "white",
          }}
        ></div>
      ) : (
        <div></div>
      )}

      <picture className="Even">
        <img
          src={`https://trime.om/api/storage/uploads` + post.image.path}
          alt={post.title}
          title={post.title}
          className="EventIMG"
          loading="lazy"
        />
      </picture>

      <div
        style={{
          position: "relative",
        }}
      >
        <div className="container grid-xl">
          {post.title && (
            <div className="Seperetor">
              <h4>{post.title}</h4>
            </div>
          )}

          <div
            className="EventContHolder"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />

          <div className="container grid-xl Event"></div>
          <div className="columns">
            <div
              style={{
                textAlign: "right",
                width: "100%",
                marginBottom: "50px"
              }}
            >
              {events ? (
                <Link className="Link SepEvent" to={events[0].title_slug}>
                  Join The Race
                </Link>
              ) : (
                <div></div>
              )}
            </div>
          </div>

          <div className="container grid-xl Event" hidden>
            <div className="columns">
              {events &&
                events.map((event, index) => {
                  return (
                    <div
                      className="column col-4 col-xs-12 col-sm-12 col-md-2 col-lg-4 col-xl-4"
                      key={index}
                      style={{
                        marginTop: "15px",
                        marginBottom: "15px",
                      }}
                    >
                      <Link className="Link" to={event.title_slug}>
                        <img
                          src={
                            `https://trime.om/api/storage/uploads` +
                            event.event_logo.path
                          }
                          alt={event.title}
                          title={event.title}
                          className="EventLogoIMGSingle"
                          loading="lazy"
                        />
                      </Link>
                      <h4 className="EventTitle">{event.title}</h4>
                      <h5 className="EventDate">{event.date}</h5>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Events;
