import React, { useState, useEffect } from "react";
import "./Home.css";

import HomeHero from "../../Components/HomeHero";
import HomeSignUp from "../../Components/HomeSignUp";
import Advertising from "../../Components/Advertising";
import HomeAboutUs from "../../Components/HomeAboutUs";
import EventsSlider from "../../Components/EventsSlider";
import Newsletter from "../../Components/Newsletter";
import AssociatesSlider from "../../Components/AssociatesSlider";
import TestimonialSlider from "../../Components/TestimonialSlider";
import Calendar from "../../Components/Calendar";


const Home = () => {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/singletons/get/settings?token=85708875aa7f8dc7d3d2752bfbce31"
      );
      await response.json();
      setIsLoading(false);
    };
    fetchData();
  }, []);

  
  
  return (
    <>
   
      <div className="Home">
      {
      isLoading ?  <div className="loading loading-lg" style={{
        position: "fixed",
        zIndex:"10",
        left: "0",
        right: "0",
        top: "0",
        bottom: "0",
        backgroundColor: "white"
      }}></div> : <div></div>
    }
        <HomeHero />
        <HomeSignUp />
        <Calendar />
        <Advertising />
        <HomeAboutUs />
        <EventsSlider />
        <Newsletter />
        <AssociatesSlider />
        <TestimonialSlider />
      </div>
    </>
  );
};

export default Home;
