import { useParams, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import "./Gallery.css";

import axios from "axios";

import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
const notyf = new Notyf();

const GallerySingle = () => {


  let params = useParams();
  ////console.log(params);

  const [post, setPost] = React.useState();
  const [events, setPostSUBGallery] = React.useState();
  const [isLoading, setIsLoading] = useState(true);
  const authedUser = sessionStorage.getItem("user");

  const [product_id, setproduct_id] = useState();
  const [price, setprice] = useState();
  const [view, setproduct_view] = useState();
  const [download, setproduct_download] = useState();

  const authedID = sessionStorage.getItem("id");
  const authedAuth = sessionStorage.getItem("auth");

  // ADD to CArt

  const handleSubmit = (event) => {
    // 👇️ prevent page refresh
    event.preventDefault();

    var data = JSON.stringify({
      data: {
        user: authedUser,
        product_id: product_id,
        price: price,
        view: view,
        download: download,
        _by: authedID,
      },
    });

    var config = {
      method: "post",
      url: "https://trime.om/api/api/collections/save/cart_image",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token" : authedAuth
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(response.data);
        notyf.success("Your request has been successfully submitted.");
        window.location.reload();
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  // 
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/sub_events?token=85708875aa7f8dc7d3d2752bfbce31",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            filter: { title_slug: params.sub_slug },
          }),
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log("Gallery Event Data", response.entries[0]);
          setPost(response.entries[0]);
          setIsLoading(false);
        });
      // List Sub Gallerys
      const responseSUBGallery = await fetch(
        "https://trime.om/api/api/collections/get/gallery?token=85708875aa7f8dc7d3d2752bfbce31&filter[Main_Event.display]=" +
        params.slug +
        "&filter[Sub_Event.display]=" +
        params.sub_slug + "&filter[gallery_title_slug]=" + params.gallery_title_slug,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
        }
      );
      await responseSUBGallery
        .json()

        .then(function (responseSUBGallery) {
          //console.log("Event Single", responseSUBGallery.entries[0]);
          setPostSUBGallery(responseSUBGallery.entries[0]);
        });
      //
    };
    fetchData();
  }, [params.sub_slug]);

  if (!post) return null;
  if (!events) return null;
  return (
    <>
      {isLoading ? (
        <div
          className="loading loading-lg"
          style={{
            position: "fixed",
            zIndex: "10",
            left: "0",
            right: "0",
            top: "0",
            bottom: "0",
            backgroundColor: "white",
          }}
        ></div>
      ) : (
        <div></div>
      )}

      <div className="GalleryHolder">
        <img
          src={`https://trime.om/api/storage/uploads` + post.event_logo.path}
          alt={post.title}
          title={post.title}
          className="GalleryIMGSingleEventLogo"
          loading="lazy"
        />
        <h3>Browse your photos & download</h3>
      </div>

      <div className="container grid-xl Event">
        <div className="columns">
          <h3 style={{
            fontSize: "20px",
            fontWeight: "700",
            margin: "10px"
          }}>{post.date}</h3>
        </div>
      </div>

      <div className="container grid-xl Event">
        <div className="columns">

          <Gallery>
            {events.gallery &&
              events.gallery.map((event, index) => {
                return (


                  <>

                    <Item
                      original={`https://trime.om/api/storage/uploads/` + event.value.view.path}
                      thumbnail={`https://trime.om/api/storage/uploads/` + event.value.view.path}
                      className="POPups"
                    >
                      {({ ref, open }) => (
                        <div
                          className="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4"
                          key={index}
                          style={{
                            marginTop: "15px",
                            marginBottom: "15px",
                          }}
                        >
                          <img
                            className="SAMEIMG"
                            ref={ref}
                            onClick={open}
                            src={`https://trime.om/api/storage/uploads/` + event.value.view.path}
                          />
                          <div
                            style={{
                              textAlign: "right",
                            }}
                          >

                            <button onClick={open} className="ShopBtnA" style={{
                              marginRight:"10px"
                            }}>
                              VIEW
                            </button>



                            {authedUser ? (
                  <form
                              id="SignUPFrom"
                              onSubmit={handleSubmit}
                            >
                             
                              <button className="ShopBtnA" type="submit" onClick={() =>(setprice(event.value.price), 
                                setproduct_id(event.value.id),
                                setproduct_view(`https://trime.om/api/storage/uploads/` + event.value.view.path),
                                setproduct_download(`https://trime.om/api/storage/uploads/` + event.value.buy.path)
                                ) } >
                                ADD TO CART
                              </button>
                            </form>
              ) : (
                   <form
                              id="SignUPFrom"
                              onSubmit={handleSubmit}
                            >
                  <Link
                to="/register"
                className="ShopBtnA"
              >
                REGISTER NOW
              </Link>
              </form>
          )}



                            



                          </div>
                        </div>
                      )}
                    </Item>
                  </>
                );
              })}
          </Gallery>
        </div>
      </div>
    </>
  );
};
export default GallerySingle;
