import { useParams } from "react-router-dom";

import React, { useState, useEffect } from "react";
import "./Page.css";

const Page = () => {
  let params = useParams();
  ////console.log(params);

  const [post, setPost] = React.useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/pages?token=85708875aa7f8dc7d3d2752bfbce31",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            filter: { title_slug: params.slug },
          }),
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log("Page Data", response.entries[0]);
          setPost(response.entries[0]);
        });
    };
    fetchData();
  }, [params.slug]);

  if (!post) return null;

  return (
    <>
      <picture className="Page">
        <source
          media="(min-width: 1281px)"
          srcSet={`https://trime.om/api/storage/uploads` + post.image.path}
        />
        <source
          media="(min-width: 601px)"
          srcSet={`https://trime.om/api/storage/uploads` + post.image_tab.path}
        />
        <source
          media="(min-width: 600px)"
          srcSet={
            `https://trime.om/api/storage/uploads` + post.image_mobile.path
          }
        />
        <img
          src={`https://trime.om/api/storage/uploads` + post.image_mobile.path}
          alt={post.title}
          title={post.title}
          className="PageIMG"
          loading="lazy"
        />
      </picture>

      <div
        style={{
          position: "relative",
        }}
      >
        {post.pager_image ? (
          <div className="container grid-xl Pager">
            <div
              style={{
                backgroundImage: `url(${
                  `https://trime.om/api/storage/uploads` + post.pager_image.path
                })`,
                height: "300px",
                width: "600px",
                position: "absolute",
                right: "0",
                top: "20px",
                zIndex: "-1",
                backgroundPosition: "top right",
                opacity: "0.15",
              }}
            >
              <div className="EffectLeft"></div>
              <div className="EffectBottom"></div>
            </div>
            <div
              className="PageContHolder"
              dangerouslySetInnerHTML={{ __html: post.content }}
            />
          </div>
        ) : (
          <div
            className="container grid-xl"
            style={{
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            <div
              className="PageContHolder"
              dangerouslySetInnerHTML={{ __html: post.content }}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default Page;
