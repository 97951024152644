import React from "react";
import axios from "axios";
import { useState } from "react";
import "./Newsletter.css";

import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
const notyf = new Notyf();

const Newsletter = (props) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    // 👇️ prevent page refresh
    event.preventDefault();

    var data = JSON.stringify({
      form: {
        email: email,
      },
    });

    var config = {
      method: "post",
      url: "https://trime.om/api/api/forms/submit/newsletters?token=85708875aa7f8dc7d3d2752bfbce31",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        ////console.log(JSON.stringify(response.data));
        notyf.success("Your request has been successfully submitted.");
        setEmail("");
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  return (
    <>
      <div className="Newsletter">
        <div className="container grid-lg">
          <div className="columns">
            <h1 className="TitleHead">NEWSLETTER</h1>
            <h2 className="SubTitleHead">
              Stay up to date with the latest events and more{" "}
            </h2>
            {/* Form */}
            <form
              id="NewsletterFrom"
              className="NewsletterForm"
              onSubmit={handleSubmit}
            >
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
                placeholder="YOUR EMAIL ADDRESS PLEASE :)"
              />

              <button type="submit">Sign up now</button>
            </form>
            {/*  */}
            <div
              style={{
                textAlign: "center",
                width: "100%",
                display: "block",
              }}
            >
              <h3 className="OfferBox">
                10% off for your next event or purchase
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Newsletter;
