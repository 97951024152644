import React from "react";
import axios from "axios";

import Menu from "../Components/Menu/footer";
import { ReactComponent as Facebook } from "./images/facebook-svgrepo-com.svg";
import { ReactComponent as Instergram } from "./images/instagram-svgrepo-com.svg";
import { ReactComponent as YouTube } from "./images/youtube-svgrepo-com.svg";
import { ReactComponent as Email } from "./images/email-svgrepo-com.svg";
import { ReactComponent as Linkedin } from "./images/linkedin-svgrepo-com.svg";

import "./Footer.css";

const Footer = (props) => {
  const [info, setPost] = React.useState(null);

  React.useEffect(() => {
    var data = "";

    var config = {
      method: "post",
      url: "https://trime.om/api/api/singletons/get/settings?token=85708875aa7f8dc7d3d2752bfbce31",
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log("FOOTER", response.data);
        setPost(response.data);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  if (!info) return null;

  return (
    <>
      <div
        className="container Footer"
        style={{
          backgroundImage: `url(${
            `https://trime.om/api/storage/uploads` +
            info.footer_background_image.path
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          //minHeight: "365px",
        }}
      >
        <div
          className="columns"
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <div className="column col-2 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <ul className="MenuHolderFoot">
              <img
                src={
                  `https://trime.om/api/storage/uploads` +
                  info.site_logo_footer.path
                }
                alt={info.site_name}
                title={info.site_name}
                className="FootLogoIMG"
                loading="lazy"
              />

              {info.foot_menu &&
                info.foot_menu.map((person, index) => {
                  return (
                    <Menu
                      key={index}
                      className="btnFooter"
                      childToParent={
                        person.value.top_page && person.value.top_page._id
                      }
                    />
                  );
                })}
            </ul>
            <div className="SMHolder">
              {info.Facebook.length > 0 && (
                <a href={info.Facebook} target="_blank">
                  <Facebook />
                </a>
              )}
              {info.Instagram.length > 0 && (
                <a href={info.Instagram} target="_blank">
                  <Instergram />
                </a>
              )}
              {info.YouTube.length > 0 && (
                <a href={info.YouTube} target="_blank">
                  <YouTube />
                </a>
              )}
              {info.Email.length > 0 && (
                <a href={`mailto:` + info.Email} target="_blank">
                  <Email />
                </a>
              )}
              {info.LinkedIn.length > 0 && (
                <a href={info.LinkedIn} target="_blank">
                  <Linkedin />
                </a>
              )}
            </div>
          </div>
          <div className="column col-10 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 Adjester">
            <div
              className="FooterHCopy"
              style={{
                textTransform: "lowercase",
              }}
            >
              <div>
                Ⓒ {new Date().getFullYear()}{" "}
                <span
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  {info.site_name}
                </span>
              </div>
              <div
                style={{
                  textTransform: "capitalize",
                }}
              >
                Designed with ♥ by{" "}
                <a
                  className="FLink"
                  href="https://www.hiendadvertising.com/"
                  target="_blank"
                >
                  Hiend Branding
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
