import React, { useEffect } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
const notyf = new Notyf();

export default function LoadGallery({ ProductID, RemoveID }) {
  const [event, setPost] = React.useState();
  const authedStore = sessionStorage.getItem("auth");

  function handleSubmit(e) {
    e.preventDefault();
    fetch("https://trime.om/api/api/collections/remove/cart_image", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": authedStore,
      },
      body: JSON.stringify({
        filter: {
          _id: RemoveID,
        },
      }),
    }).then((response) => {
      notyf.success("Your Product has been Deleted.");
      window.location.reload();
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://trime.om/api/api/collections/get/gallery?token=85708875aa7f8dc7d3d2752bfbce31",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
        }
      );
      await response
        .json()

        .then(function (response) {
          //console.log("HI", response.entries);
          setPost(response.entries);
        });
    };
    fetchData();
  }, [ProductID, RemoveID]);

  if (!event) return null;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <button
          style={{
            border: "1px solid balck",
            cursor: "pointer",
            position: "absolute",
            zIndex: "1",
            color:"balck"
          }}
          type="submit"
        >
          Remove
        </button>
      </form>
      <img
        src={ProductID}
        alt={event.title}
        title={event.title}
        className="CartOfferIMG"
        loading="lazy"
      />
    </>
  );
}
