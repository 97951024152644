import React from "react";
import axios from "axios";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function Child({ childToParentSubEvets }) {
  const baseURL =
    "https://trime.om/api/api/collections/get/sub_events?token=85708875aa7f8dc7d3d2752bfbce31&filter[event.display]=" +
    childToParentSubEvets + "&filter[published]=true";

  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    axios.get(baseURL).then((response) => {
      setPost(response.data.entries);
      ////console.log("EVENTS MENU SUB", response.data.entries);
    });
  }, []);

  if (!post) return null;

  return (
    <>
      {childToParentSubEvets === "trime"}
      {post &&
        post.map((event, index) => {
          return (
            <CustomLink
            key={ index}
              className="EventSub"
              title={event.title}
              to={`/events/trime/` + event.title_slug}
            >
              {event.title_slug}
            </CustomLink>
          );
        })}
    </>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
