import React from "react";
import axios from "axios";
import "./HomeHero.css";

import Lottie from "react-lottie";
import * as animationWeb from "./Animations/data.json";
import * as animationTab from "./Animations/dataTAB.json";

const HomeHero = (props) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationWeb,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptionsTAB = {
    loop: false,
    autoplay: true,
    animationData: animationTab,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  function resizeIframe(obj) {
    obj.style.height =
      obj.contentWindow.document.documentElement.scrollHeight + "px";
  }

  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    var data = "";

    var config = {
      method: "get",
      url: "https://trime.om/api/api/collections/entry/pages/ce3651d763373770c1000372?token=85708875aa7f8dc7d3d2752bfbce31",
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        ////console.log(response.data);
        setPost(response.data);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  if (!post) return null;

  return (
    <>
      <div className="hide-md">
        {" "}
        <Lottie options={defaultOptions} />
      </div>
      <div className="show-md">
        <Lottie options={defaultOptionsTAB} />
      </div>

      {/* <picture>
        <source
          media="(min-width: 1281px)"
          srcSet={`https://trime.om/api/storage/uploads` + post.image.path}
        />
        <source
          media="(min-width: 601px)"
          srcSet={`https://trime.om/api/storage/uploads` + post.image_tab.path}
        />
        <source
          media="(min-width: 600px)"
          srcSet={
            `https://trime.om/api/storage/uploads` + post.image_mobile.path
          }
        />
        <img
          src={`https://trime.om/api/storage/uploads` + post.image_mobile.path}
          alt={post.title}
          className="HomeSliderIMG"
          loading="lazy"
        />
      </picture> */}
    </>
  );
};

export default HomeHero;
